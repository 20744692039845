import React from 'react';
import { makeStyles, Grow, Grid, Hidden, Typography } from '@material-ui/core';
import Carousel from 'react-elastic-carousel';
import '../css/CarouselCss.css'

import Marquee from "react-fast-marquee";

import * as myConstants from './Constants';

// import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import car1 from '../assets/carousel/ps-car-1.jpg';
import car2 from '../assets/carousel/ps-car-2.jpg';
import car3 from '../assets/carousel/ps-car-3.jpg';
import car4 from '../assets/carousel/ps-car-4.jpg';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        minWidth: 500,
        width: '100%',
        marginTop: '10px'
    },
    image: {
        position: 'relative',
        height: '45vh',
        '&:hover, &$focusVisible': {
            zIndex: 1,
            '& $imageBackdrop': {
                opacity: 0.15,
            },
            '& $imageMarked': {
                opacity: 0,
            },
            '& $imageTitle': {
                border: '4px solid currentColor',
            },
        },
    },
    focusVisible: {},
    imageButton: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        color: theme.palette.common.white,
    },
    imageSrc: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundSize: 'cover',
        backgroundPosition: 'center 40%',
    },
    imageBackdrop: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: theme.palette.common.black,
        opacity: 0.4,
        transition: theme.transitions.create('opacity'),
    },
    imageTitle: {
        position: 'relative',
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
    },
    imageMarked: {
        height: 3,
        width: 18,
        backgroundColor: theme.palette.common.white,
        position: 'absolute',
        bottom: -2,
        left: 'calc(50% - 9px)',
        transition: theme.transitions.create('opacity'),
    },
    images: {
        // maxHeight: '80vh'
        height: '750px',
        [theme.breakpoints.down('sm')]: {
            height: '300px',
        }
    },
    contain: {
        // width: '300px'
    },
    imageC: {
        height: '87vh',
        width: 'auto',
        transition: 'height 0.5s ease-in',
    },
    bgmar: {
        textAlign: 'center',
        height: '30px',
        backgroundImage: 'linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }

}))


const ImgCarousel = () => {

    const classes = useStyles();

    const carouselRef = React.createRef();

    const images = [
        {
            url: car1,
            title: 'An Eminent Wealth Management Services',
            width: '99.9%',
        },
        {
            url: car2,
            title: 'Trustworthy Investment Management',
            width: '33.3%',
        },
        {
            url: car3,
            title: 'A Smart Financial Solution',
            width: '33.3%',
        },
        {
            url: car4,
            title: 'Happy Retirement Plans',
            width: '33.3%',
        }
    ];

    return (
        <Grow in>
            <div>
                {myConstants.BANNER_TEXT &&
                    <Grid container justifycontent="center" alignItems="center">
                        <Grid item xs={12}>
                            <div className={classes.bgmar}>
                                <Marquee
                                    direction="right"
                                    speed={100}
                                    pauseOnHover
                                    gradientWidth={0}
                                >
                                    <Typography variant="subtitle2" color="textSecondary">
                                        {myConstants.BANNER_TEXT}
                                    </Typography>
                                </Marquee>
                            </div>
                        </Grid>
                    </Grid>
                }
                <Grid container justifycontent="center" alignItems="center">
                    <Hidden smDown>
                        {/* <Carousel
                        autoPlay
                        showStatus={false}
                        infiniteLoop
                        showThumbs={false}
                        interval={5000}
                        transitionTime={400}
                        dynamicHeight
                    >
                        {
                            images.map((image, index) => (
                                <div key={index}>
                                    <img src={image} alt={`-${index}`} className={classes.images} />
                                </div>
                            ))
                        }
                    </Carousel> */}
                        {/* <div className={classes.root}>
                            {images.map((image) => (
                                <ButtonBase
                                    focusRipple
                                    key={image.title}
                                    className={classes.image}
                                    focusVisibleClassName={classes.focusVisible}
                                    style={{
                                        width: image.width,
                                    }}
                                >
                                    <span
                                        className={classes.imageSrc}
                                        style={{
                                            backgroundImage: `url(${image.url})`,
                                        }}
                                    />
                                    <span className={classes.imageBackdrop} />
                                    <span className={classes.imageButton}>
                                        <Typography
                                            component="span"
                                            variant="subtitle1"
                                            color="inherit"
                                            className={classes.imageTitle}
                                        >
                                            {image.title}
                                            <span className={classes.imageMarked} />
                                        </Typography>
                                    </span>
                                </ButtonBase>
                            ))}
                        </div> */}
                        <Carousel
                            easing="ease"
                            tiltEasing="ease"
                            enableAutoPlay
                            autoPlaySpeed={10000}
                            disableArrowsOnEnd={false}
                            preventDefaultTouchmoveEvent
                            // showArrows={false}
                            ref={carouselRef}
                            onNextStart={(nextItemObject, currentPageIndex) => {
                                currentPageIndex?.index === (images.length - 1) && carouselRef.current.goTo(0)
                            }}
                        >
                            {images.map(image =>
                                <div key={image.title}>
                                    <img src={image.url} alt={image.title} className={classes.imageC} />
                                </div>
                            )}
                        </Carousel>
                    </Hidden>
                </Grid>
            </div>
        </Grow>
    )
}

export default ImgCarousel;
