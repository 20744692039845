import React from 'react';
import Helmet from 'react-helmet';

import { Grid, Typography, makeStyles, Grow } from '@material-ui/core';

import DriveEtaOutlinedIcon from '@material-ui/icons/DriveEtaOutlined';
import TrendingUpOutlinedIcon from '@material-ui/icons/TrendingUpOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
// import FaceOutlinedIcon from '@material-ui/icons/FaceOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: `${theme.spacing(5)}px`,
        // height: '70vh',
    },
    hov: {
        display: 'inline-block',
        cursor: 'default',
        backgroundImage: 'linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '20% 0.2em',
        backgroundPosition: '0 95%',
        transition: 'background-size 0.25s ease-in',
        '&:hover': {
            backgroundSize: '100% 88%'
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '4rem'
        }
    },
    hovDown: {
        cursor: 'default',
        [theme.breakpoints.down('sm')]: {
            fontSize: '2rem'
        }
    },
    Heading: {
        height: '90vh',
        [theme.breakpoints.down('sm')]: {
            height: '50vh'
        }
    },
    headings: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: `${theme.spacing(6)}px`,
        [theme.breakpoints.down('sm')]: {
            marginBottom: `${theme.spacing(3)}px`
        }
    },
    iconpack: {
        display: 'flex'
    },
    icons: {
        color: '#000',
        fontSize: '8rem',
        margin: `0 ${theme.spacing(2)}px`,
        border: '2px solid #84fab0',
        padding: `${theme.spacing(2)}px`,
        borderRadius: theme.spacing(2),
        cursor: 'pointer',
        backgroundImage: 'linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '40% 10%',
        backgroundPosition: '50% 100%',
        transition: 'all 0.25s ease-in',
        '&:hover': {
            backgroundSize: '100% 100%',
            color: '#fff'
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: '6rem',
            margin: `0 ${theme.spacing(1)}px`,
            padding: `${theme.spacing(1)}px`,
        }
    }
}));

const Heading = () => {

    const classes = useStyles();

    // React.useEffect(() => {
    //     document.title = 'Investment Managament Services in Dindigul | Asset Management Firms in Dindigul';
    //     document.description = 'New Horizon is one of the best Investment Management services in Dindigul, India. As asset management firm in Dindigul, we render the right financial planning, investment and real estate advisory.';
    //     document.keywords = 'Asset Management Firms in Dindigul, Investment Management Services in Dindigul, Investment Management in Dindigul, Investment Portfolio Management in Dindigul';
    // }, []);

    return (
        <>
            <Helmet>
                <title>Investment Managament Services in Dindigul | Asset Management Firms in Dindigul</title>
                <meta name='description' content='New Horizon is one of the best Investment Management services in Dindigul, India. As asset management firm in Dindigul, we render the right financial planning, investment and real estate advisory.' />
                <meta name='keywords' content='Asset Management Firms in Dindigul, Investment Management Services in Dindigul, Investment Management in Dindigul, Investment Portfolio Management in Dindigul' />
                <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                <link rel="canonical" href="https://www.newhorizonwealth.in/" />
                <meta property="og:locale" content="en_IN" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Investment Managament Services in Dindigul | Asset Management Firms in Dindigul" />
                <meta property="og:description" content="New Horizon is one of the best Investment Management services in Dindigul, India. As asset management firm in Dindigul, we render the right financial planning, investment and real estate advisory." />
                <meta property="og:url" content="https://www.newhorizonwealth.in/" />
                <meta property="og:site_name" content="New Horizon" />
                <meta property="og:image" content="https://www.newhorizonwealth.in/static/media/logo.c8d9e4cd.png" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:label1" content="Written by" />
                <meta name="twitter:data1" content="New Horizon" />
                <meta name="twitter:label2" content="Est. reading time" />
                <meta name="twitter:data2" content="2 minutes" />
            </Helmet>
            <Grow in>
                <div className={classes.root}>
                    <Grid
                        container
                        spacing={0}
                        alignItems="center"
                        justify="center"
                        direction="column"
                        className={classes.Heading}
                    >
                        <Grid item className={classes.headings}>
                            <Typography variant="h1" component="h1" gutterBottom>
                                <div className={classes.hov}>
                                    New Horizon.
                                </div>
                            </Typography>
                            <Typography variant="h3" component="h3" gutterBottom>
                                <div className={classes.hovDown}>
                                    Wealth Management Services
                                </div>
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        justify="center"
                    // style={{ height: '30vh' }}
                    >
                        <Grid item>
                            <DriveEtaOutlinedIcon fontSize="large" className={classes.icons} />
                        </Grid>
                        <Grid item>
                            <FavoriteBorderOutlinedIcon fontSize="large" className={classes.icons} />
                        </Grid>
                        <Grid item>
                            <TrendingUpOutlinedIcon fontSize="large" className={classes.icons} />
                        </Grid>
                        <Grid item>
                            <HomeOutlinedIcon fontSize="large" className={classes.icons} />
                        </Grid>
                        <Grid item>
                            <MonetizationOnOutlinedIcon fontSize="large" className={classes.icons} />
                        </Grid>
                    </Grid>
                </div>
            </Grow>
        </>
    )
}

export default Heading
