import React from 'react';

import { Typography, makeStyles, Grow } from '@material-ui/core';

import SettingsTwoToneIcon from '@material-ui/icons/SettingsTwoTone';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'
    },
    icon: {
        fontSize: '7rem',
    }
}));

const UnderConstruction = (props) => {

    const classes = useStyles();

    return (
        <Grow in>
            <div className={classes.root}>
                <Typography variant="subtitle1" style={{ fontWeight: 300 }}>Website Under Construction!</Typography>
                <SettingsTwoToneIcon color="secondary" className={classes.icon} id="under-construction-gear" />
                <Typography variant="h5" style={{ fontWeight: 400 }}>Coming Soon...</Typography>
            </div>
        </Grow>
    )
}

export default UnderConstruction;
