import React from 'react';

import { Grid, Typography, makeStyles, Grow } from '@material-ui/core';

import Marquee from "react-fast-marquee";

import health1 from '../assets/partners/Health/1.jpeg';
import health2 from '../assets/partners/Health/2.jpeg';
import health3 from '../assets/partners/Health/3.jpeg';
import health4 from '../assets/partners/Health/4.jpeg';

import life1 from '../assets/partners/Life/1.jpeg';
import life2 from '../assets/partners/Life/2.jpeg';
import life3 from '../assets/partners/Life/3.jpeg';
import life4 from '../assets/partners/Life/4.jpeg';
import life5 from '../assets/partners/Life/5.jpeg';
import life6 from '../assets/partners/Life/6.jpeg';
import life7 from '../assets/partners/Life/7.jpeg';
import life8 from '../assets/partners/Life/8.jpeg';
import life9 from '../assets/partners/Life/9.jpeg';

import general1 from '../assets/partners/General/1.jpeg';
import general2 from '../assets/partners/General/2.jpeg';
import general3 from '../assets/partners/General/3.jpeg';
import general4 from '../assets/partners/General/4.jpeg';
import general5 from '../assets/partners/General/5.jpeg';
import general6 from '../assets/partners/General/6.jpeg';
import general7 from '../assets/partners/General/7.jpeg';
import general8 from '../assets/partners/General/8.jpeg';
import general9 from '../assets/partners/General/9.jpeg';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: `${theme.spacing(3)}px`
    },
    hov: {
        display: 'inline-block',
        cursor: 'default',
        backgroundImage: 'linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '20% 0.2em',
        backgroundPosition: '0 95%',
        transition: 'background-size 0.25s ease-in',
        marginBottom: '5vh',
        '&:hover': {
            backgroundSize: '100% 88%'
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: '0px',
            fontSize: '3rem'
        }
    },
    hovSub: {
        display: 'inline-block',
        cursor: 'default',
        backgroundImage: 'linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '20% 0.2em',
        backgroundPosition: '0 95%',
        transition: 'background-size 0.25s ease-in',
        marginBottom: '1vh',
        '&:hover': {
            backgroundSize: '100% 88%'
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: '0px',
            fontSize: '2rem'
        }
    },
    gridContain: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
        // marginBottom: theme.spacing(5),
    },
    consultation: {
        width: '80%',
        fontSize: '1.1rem',
        textAlign: 'center',
        height: '50px',
        transform: 'skew(340deg)',
        backgroundImage: 'linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.9rem',
            height: '65px',
        }
    },
    cta: {
        marginLeft: theme.spacing(2),
        fontWeight: 'bold',
        color: 'white',
        transform: 'skew(20deg)',
    },
    img: {
        height: '100px',
        width: 'auto',
        margin: theme.spacing(2),
        filter: 'grayscale(100%)',
        '&::-webkit-filter': 'grayscale(100%)',
        // cursor: 'pointer',
        borderRadius: '5%',
        transition: 'filter 0.1s ease-in',
        [theme.breakpoints.down('sm')]: {
            height: '70px',
            width: 'auto',
            filter: 'none',
            '&::-webkit-filter': 'grayscale(0)',
        },
        '&:hover': {
            filter: 'none',
            '&::-webkit-filter': 'grayscale(0)',
        },
    },
    centerGrid: {
        display: 'flex',
        justifyContent: 'center'
    }
}));

const Ourpartners = (props) => {

    const classes = useStyles({});

    const health = [health1, health2, health3, health4];
    const life = [life1, life2, life3, life4, life5, life6, life7, life8, life9];
    const general = [general1, general2, general3, general4, general5, general9, general8, general7, general6];

    return (
        <Grow in>
            <div className={classes.root} id="partners">
                <Grid container justify="center" alignItems="center" style={{ height: '20vh' }}>
                    <Grid item>
                        <Typography variant="h2" component="div" className={classes.hov}>
                            Our Partners
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container justify="center" alignItems="center" spacing={2} className={classes.gridContain}>
                    <Grid item xs={10} sm={9} md={9} className={classes.contentText}>
                        <Typography variant="h4" component="div" className={classes.hovSub}>
                            Life Insurance
                        </Typography>
                    </Grid>
                    <Grid item xs={10} sm={9} md={9} className={classes.contentText}>
                        <div className={classes.centerGrid}>
                            <Marquee
                                speed={60}
                                gradientWidth={30}
                                pauseOnHover
                                // gradientColor={[29, 233, 182]}
                            >
                                {
                                    life.map(
                                        (img, index) => (
                                            <img src={img} key={index} alt="Life" className={classes.img} />
                                        ))
                                }
                            </Marquee>
                        </div>
                    </Grid>
                    {/* <Grid item xs={1}></Grid> */}
                </Grid>
                <Grid container justify="center" alignItems="center" spacing={2} className={classes.gridContain}>
                    <Grid item xs={10} sm={9} md={9} className={classes.contentText}>
                        <Typography variant="h4" component="div" className={classes.hovSub}>
                            Health Insurance
                        </Typography>
                    </Grid>
                    <Grid item xs={10} sm={9} md={9} className={classes.contentText}>
                        <div className={classes.centerGrid}>
                            <Marquee
                                speed={60}
                                direction="right"
                                gradientWidth={30}
                                pauseOnHover
                                // gradientColor={[29, 233, 182]}
                            >
                                {
                                    health.map(
                                        (img, index) => (
                                            <img src={img} key={index} alt="Health" className={classes.img} />
                                        ))
                                }
                            </Marquee>
                        </div>
                    </Grid>
                    {/* <Grid item xs={1}></Grid> */}
                </Grid>
                <Grid container justify="center" alignItems="center" spacing={2} className={classes.gridContain}>
                    <Grid item xs={10} sm={9} md={9} className={classes.contentText}>
                        <Typography variant="h4" component="div" className={classes.hovSub}>
                            General Insurance
                        </Typography>
                    </Grid>
                    <Grid item xs={10} sm={9} md={9} className={classes.contentText}>
                        <div className={classes.centerGrid}>
                            <Marquee
                                speed={60}
                                gradientWidth={30}
                                pauseOnHover
                                // gradientColor={[29, 233, 182]}
                            >
                                {
                                    general.map(
                                        (img, index) => (
                                            <img src={img} key={index} alt="General" className={classes.img} />
                                        ))
                                }
                            </Marquee>
                        </div>
                    </Grid>
                    {/* <Grid item xs={1}></Grid> */}
                </Grid>
            </div>
        </Grow>
    )
}

export default Ourpartners