import React from "react";

import { Grid, Typography, makeStyles, Grow } from "@material-ui/core";
import { Link } from "react-router-dom";

import svg1 from "../assets/svg/1.svg";
import svg2 from "../assets/svg/2.svg";
import svg3 from "../assets/svg/3.svg";
import svg4 from "../assets/svg/4.svg";
import svg5 from "../assets/svg/5.svg";
import svg6 from "../assets/svg/6.svg";
import svg7 from "../assets/svg/7.svg";
import svg8 from "../assets/svg/8.svg";
import svg9 from "../assets/svg/9.svg";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: `${theme.spacing(5)}px`,
  },
  svg: {
    height: "100px",
    width: "auto",
    [theme.breakpoints.down("sm")]: {
      height: "50px",
      width: "auto",
    },
  },
  outlayer: {
    margin: `${theme.spacing(2)}px auto`,
    padding: theme.spacing(2),
    border: "2px solid #84fab0",
    borderRadius: theme.spacing(2),
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    width: "50%",
    textDecoration: "none",
    cursor: "pointer",
    backgroundImage: 'linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '60% 5%',
    backgroundPosition: '50% 100%',
    transition: 'all 0.25s ease-in',
    '&:hover': {
        backgroundSize: '100% 100%',
        color: '#fff'
    },
    [theme.breakpoints.down("sm")]: {
      margin: `${theme.spacing(0.5)}px`,
      width: "auto",
    },
  },
  caption: {
    textAlign: "center",
    color: "#000",
    textTransform: "uppercase",
    fontWeight: 500,
    marginTop: theme.spacing(2),
    cursor: "default",
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.5rem",
    },
  },
  hov: {
    display: "inline-block",
    cursor: "default",
    backgroundImage: "linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%)",
    backgroundRepeat: "no-repeat",
    backgroundSize: "20% 0.2em",
    backgroundPosition: "0 95%",
    transition: "background-size 0.25s ease-in",
    marginBottom: "5vh",
    "&:hover": {
      backgroundSize: "100% 88%",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "0px",
      fontSize: "3rem",
    },
  },
  contentHeight: {
    minHeight: "70vh",
  }
}));

const Products = (props) => {
  const classes = useStyles();

  const serviceImg = [svg1, svg2, svg3, svg4, svg5, svg6, svg7, svg8, svg9];

  const services = [
    "Insurance",
    "Mutual Funds",
    "Share Trading",
    "Gold Investments",
    "Deposits",
    "Loans",
    "Real Estates",
    "Chits",
    "E-Stamping",
  ];

  return (
    <Grow in>
      <div className={classes.root} id="products-section">
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ height: "20vh" }}
        >
          <Grid item>
            <Typography variant="h2" component="div" className={classes.hov}>
              Products
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          justify="center"
          alignItems="center"
          alignContent="center"
          className={classes.contentHeight}
        >
          {serviceImg.map((img, index) => (
            <Grid item xs={4} key={index}>
              <Link to="/services" className={classes.outlayer}>
                <img src={img} alt="img" className={classes.svg} />
                <Typography
                  variant="body1"
                  component="div"
                  className={classes.caption}
                >
                  {services[index]}
                </Typography>
              </Link>
            </Grid>
          ))}
        </Grid>
      </div>
    </Grow>
  );
};

export default Products;
