import React from 'react';

import { Grid, Typography, makeStyles, Grow, Button, Box, Hidden } from '@material-ui/core';

import * as myConstants from './Constants';

import help from '../assets/services/help.svg';

import insurance from '../assets/services/insurance.svg';
import mutual from '../assets/services/mutual.svg';
import investment from '../assets/services/investment.svg';
import realestate from '../assets/services/realestate.svg';
import loan from '../assets/services/loan.svg';
import deposit from '../assets/services/deposit.svg';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: `${theme.spacing(1)}px`
    },
    hov: {
        display: 'inline-block',
        cursor: 'default',
        backgroundImage: 'linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '20% 0.2em',
        backgroundPosition: '0 95%',
        transition: 'background-size 0.25s ease-in',
        marginBottom: '5vh',
        '&:hover': {
            backgroundSize: '100% 88%'
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: '40px',
            fontSize: '3rem'
        }
    },
    hovSub: {
        display: 'inline-block',
        cursor: 'default',
        backgroundImage: 'linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '20% 0.2em',
        backgroundPosition: '0 95%',
        transition: 'background-size 0.25s ease-in',
        marginBottom: '1vh',
        '&:hover': {
            backgroundSize: '100% 88%'
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: '10px'
        }
    },
    gridContain: {
        height: 'auto',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            height: 'auto',
        }
    },
    gridContain2: {
        height: '70vh',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            height: 'auto',
        }
    },
    content: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },
    contentText2: {
        display: 'flex',
        justifyContent: 'center',
        margin: theme.spacing(3)
    },
    consultation: {
        width: '80%',
        fontSize: '1.1rem',
        textAlign: 'center',
        height: '50px',
        transform: 'skew(340deg)',
        backgroundImage: 'linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.9rem',
        }
    },
    cta: {
        marginLeft: theme.spacing(2),
        fontWeight: 'bold',
        color: 'white',
        transform: 'skew(20deg)',
    },
    text: {
        textAlign: 'justify',
        textJustify: 'inter-word'
    },
    svg: {
        margin: theme.spacing(2),
        height: '170px',
        width: 'auto',
        [theme.breakpoints.down('sm')]: {
            height: '120px',
            width: 'auto',
        }
    },
    flexCenter: {
        display: 'flex',
        justifyContent: 'center'
    },
    contentSapcing: {
        margin: theme.spacing(2)
    }
}));

const Howwehelp = (props) => {

    const classes = useStyles({});

    const cta = (content, ctaCall) => {
        return (
            <Grid container justify="center" className={classes.gridContain}>
                <Grid item sm={12} className={classes.content}>
                    <Typography className={classes.consultation} >
                        {content} {ctaCall ? ctaCall : ''}
                        <Button variant="contained" color="secondary" className={classes.cta} >
                            <a href={`tel:${myConstants.SHORT_PHONE_NUMBER}`} style={{ cursor: 'pointer', textDecoration: 'none', color: 'inherit' }}>
                                {ctaCall ? '' : 'Call us : '}{myConstants.SHORT_PHONE_NUMBER}
                            </a>
                        </Button>
                    </Typography>
                </Grid>
            </Grid>
        )
    };

    const IconCommon = (img) => {
        return <Grid item xs={10} sm={9} md={3} className={classes.contentText}>
            <div className={classes.flexCenter}>
                <img src={img} alt="img" className={classes.svg} />
            </div>
        </Grid>
    };

    return (
        <Grow in>
            <div className={classes.root} id="what-we-do">
                <Grid container justify="center" alignItems="center" style={{ height: '20vh' }}>
                    <Grid item>
                        <Typography variant="h2" component="div" className={classes.hov}>
                            How We Can Help You
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container justify="center" spacing={2}>
                    <Grid item xs={10} sm={9} md={9} >
                        <div className={classes.content}>
                            <img src={help} alt="img" className={classes.svgs} />
                        </div>
                    </Grid>
                    <Grid item xs={10} sm={9} md={9} >
                        <Typography variant="subtitle1" color="textSecondary" className={classes.text}>
                            Let's work together with our best financial professionals and intellectual wealth advisors to ensure a bright future for you and to help you succeed in your goals. Our goal is to help people of any age make the most of their money.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container justify="center" alignItems="center" className={classes.gridContain}>
                    <Grid item xs={10} sm={9} md={9} className={classes.contentText2}>
                        <Typography variant="h4" component="div" className={classes.hovSub}>
                            Insurance Planning
                        </Typography>
                    </Grid>
                    <Grid item xs={10} sm={9} md={9} className={classes.contentText2}>
                        <Typography color="textSecondary" className={classes.text}>
                            Having financial cover for yourself is worth it and lets you take the umbrella of wide insurance services available. We are here to do the insurance properly, whether it's a life or health, or two-wheeler or car or 'other vehicles. The agents at our agency are experts at finding the best insurance option which meets all your requirements.
                        </Typography>
                    </Grid>
                    <Hidden mdUp>
                        {IconCommon(insurance)}
                    </Hidden>
                    <Grid item xs={10} sm={9} md={6}>
                        <Typography color="textSecondary" className={classes.text}>
                            <Box fontWeight="fontWeightBold">
                                Our Expertise includes:
                            </Box>
                        </Typography>
                        <Typography color="textSecondary" className={classes.text}>
                            <Box>
                                Simplifying and streamlining the insurance process.
                            </Box>
                        </Typography>
                        <Typography color="textSecondary" className={classes.text}>
                            <Box>
                                Monitoring the actual market value of your policy.
                            </Box>
                        </Typography>
                        <br />
                        <Typography color="textSecondary" className={classes.text}>
                            <Box>
                                Some of our Insurance Planning Services:
                            </Box>
                        </Typography>
                        <Typography color="textSecondary" className={classes.text}>
                            <Box>
                                •	Life Insurance
                            </Box>
                            <Box>
                                •	Health Insurance
                            </Box>
                            <Box>
                                •	Two-wheeler Insurance
                            </Box>
                            <Box>
                                •	Child Cover
                            </Box>
                        </Typography>
                    </Grid>
                    <Hidden smDown>
                        {IconCommon(insurance)}
                    </Hidden>
                </Grid >
                {cta('Insure yourself and your family with the right scheme. Now, let us talk.', 'Now, let us talk.')}
                <Grid container justify="center" alignItems="center" className={classes.gridContain}>
                    <Grid item xs={10} sm={9} md={9} className={classes.contentText2}>
                        <Typography variant="h4" component="div" className={classes.hovSub}>
                            Mutual Funds
                        </Typography>
                    </Grid>
                    <Grid item xs={10} sm={9} md={9} className={classes.contentText2}>
                        <Typography color="textSecondary" className={classes.text}>
                            Mutual funds will ensure that you get the best return if you invest wisely, no matter your financial goals. You can build your wealth easily with mutual funds. Our research team will analyze different mutual fund schemes to identify the best fit that meets your needs. Our team of expert fund managers puts you on the right path from fund selection to investment and tracking services. Our simplified approach and smart solutions we offer will let you invest in top-performing schemes.
                        </Typography>
                    </Grid>
                    <Hidden mdUp>
                        {IconCommon(mutual)}
                    </Hidden>
                    <Grid item xs={10} sm={9} md={6}>
                        <Typography color="textSecondary" className={classes.text}>
                            <Box fontWeight="fontWeightBold">
                                How We Do for You
                            </Box>
                        </Typography>
                        <br />
                        <Typography color="textSecondary" className={classes.text}>
                            <Box>
                                •	Real-time researches are used to determine the most returnable schemes.
                            </Box>
                            <Box>
                                •	A smart manager will manage your fund.
                            </Box>
                            <Box>
                                •	Easily track, manage, and analyze your investment portfolio with us.
                            </Box>
                        </Typography>
                    </Grid>
                    <Hidden smDown>
                        {IconCommon(mutual)}
                    </Hidden>
                </Grid >
                <Grid container justify="center" alignItems="center" className={classes.gridContain}>
                    <Grid item xs={10} sm={9} md={9} className={classes.contentText2}>
                        <Typography variant="h4" component="div" className={classes.hovSub}>
                            Smart Investment Planning
                        </Typography>
                    </Grid>
                    <Grid item xs={10} sm={9} md={9} className={classes.contentText2}>
                        <Typography color="textSecondary" className={classes.text}>
                            <Box>
                                Successful investing requires knowledge, dedication, and the right timing, which is why smart investors choose to hire an expert.
                            </Box>
                            <Box>
                                Here at New Horizons, we work along with you through your investing journey, managing your investments, guiding you throughout, and giving expert advice on all investment decisions.
                            </Box>
                            <Box>
                                We provide bespoke investment solutions to a diverse base of firms and individual clients throughout all major asset classes.
                            </Box>
                        </Typography>
                    </Grid>
                    <Hidden mdUp>
                        {IconCommon(investment)}
                    </Hidden>
                    <Grid item xs={10} sm={9} md={6}>
                        <Typography color="textSecondary" className={classes.text}>
                            <Box fontWeight="fontWeightBold">
                                We have best-in-class and experienced investment professionals who come up with an intensive investment plan for you.
                            </Box>
                        </Typography>
                        <br />
                        <Typography color="textSecondary" className={classes.text}>
                            <Box>
                                •	Our technology-based investment solutions are customized to meet your needs.
                            </Box>
                            <Box>
                                •	We always follow a Transparent structure and investment process.
                            </Box>
                            <Box>
                                •	Focusing on capital raising through alternative investment options.
                            </Box>
                        </Typography>
                    </Grid>
                    <Hidden smDown>
                        {IconCommon(investment)}
                    </Hidden>
                </Grid >
                {cta('Let\'s figure out your best investment strategy')}
                <Grid container justify="center" alignItems="center" className={classes.gridContain}>
                    <Grid item xs={10} sm={9} md={9} className={classes.contentText2}>
                        <Typography variant="h4" component="div" className={classes.hovSub}>
                            Real Estate
                        </Typography>
                    </Grid>
                    <Grid item xs={10} sm={9} md={9} className={classes.contentText2}>
                        <Typography color="textSecondary" className={classes.text}>
                            <Box>
                                Real estate in India is growing faster than most, and it plays an integral part in both your financial portfolio and investment portfolio. Aside from housing, real estate includes commercial areas, house places, retail places, and property areas which are a wide sector.                             </Box>
                            <Box>
                                The construction market is going strong parallel to the growth in the real estate market, which is the most promising opportunity available. The real estate market is dynamic, fragmented, and unwieldy. That's why we are here to help you manage the real estate sector more comfortably.
                             </Box>
                            <Box>
                                We offer various real estate-related services, whether you're buying or selling property areas or housing, looking for rental places, or looking for investment options.
                             </Box>
                        </Typography>
                    </Grid>
                    <Hidden mdUp>
                        {IconCommon(realestate)}
                    </Hidden>
                    <Grid item xs={10} sm={9} md={6}>
                        <Typography color="textSecondary" className={classes.text}>
                            <Box fontWeight="fontWeightBold">
                                Our services include:
                            </Box>
                        </Typography>
                        <br />
                        <Typography color="textSecondary" className={classes.text}>
                            <Box fontWeight="fontWeightBold">
                                •	Real Estate Advisory Services:
                            </Box>
                            <Box>
                                Get an insight into the market before stepping in with our expert help.
                            </Box>
                            <Box fontWeight="fontWeightBold">
                                •	Direct Property Purchase
                            </Box>
                            <Box>
                                Select the right property for you to move into and we will guide you at all times.
                            </Box>
                            <Box fontWeight="fontWeightBold">
                                •	Real Estate Broking Services:
                            </Box>
                            <Box>
                                Let you get the best.
                            </Box>
                            <Box fontWeight="fontWeightBold">
                                •	Real Estate Investment:
                            </Box>
                            <Box>
                                Our professionals develop smart investment strategies that help you earn steady and risk-free returns.
                            </Box>
                        </Typography>
                    </Grid>
                    <Hidden smDown>
                        {IconCommon(realestate)}
                    </Hidden>
                </Grid >
                {cta('Shape your financial future by holding your ground in real estate.')}
                <Grid container justify="center" alignItems="center" className={classes.gridContain}>
                    <Grid item xs={10} sm={9} md={9} className={classes.contentText2}>
                        <Typography variant="h4" component="div" className={classes.hovSub}>
                            Loans Services
                        </Typography>
                    </Grid>
                    <Grid item xs={10} sm={9} md={9} className={classes.contentText2}>
                        <Typography color="textSecondary" className={classes.text}>
                            Don’t let money to the barrier to achieving your dreams. With an in-depth analysis, we come up with a best-in-class customized loan solution for all your needs. Right from personal loans to business loans, loans against properties to loans against share, we assist you to give the shape for your dreams. It's easy to be overwhelmed by the loan process, but our experts are here to help you.
                        </Typography>
                    </Grid>
                    <Hidden mdUp>
                        {IconCommon(loan)}
                    </Hidden>
                    <Grid item xs={10} sm={9} md={6}>
                        <Typography color="textSecondary" className={classes.text}>
                            <Box fontWeight="fontWeightBold">
                                We offer a wide range of loan services
                            </Box>
                        </Typography>
                        <br />
                        <Typography color="textSecondary" className={classes.text}>
                            <Box>
                                •	Home Loan
                            </Box>
                            <Box>
                                •	Home Loan
                            </Box>
                            <Box>
                                •	Business Loan
                            </Box>
                            <Box>
                                •	Loan Against Property
                            </Box>
                            <Box>
                                •	Personal loan
                            </Box>
                            <Box>
                                •	Loan Against Shares, Securities, Mutual Funds
                            </Box>
                            <Box>
                                •	Educational Loan
                            </Box>
                        </Typography>
                    </Grid>
                    <Hidden smDown>
                        {IconCommon(loan)}
                    </Hidden>
                </Grid >
                {cta('We want to help you transform your dreams into reality with the right finance.', 'Give us a Ring.')}
                <Grid container justify="center" alignItems="center" className={classes.gridContain}>
                    <Grid item xs={10} sm={9} md={9} className={classes.contentText2}>
                        <Typography variant="h4" component="div" className={classes.hovSub}>
                            Deposits
                        </Typography>
                    </Grid>
                    <Grid item xs={10} sm={9} md={9} className={classes.contentText2}>
                        <Typography color="textSecondary" className={classes.text}>
                            Deposits may sound easy, but you need to have great insight into choosing the right destination and plans. Just rely on our experts for identifying the right plan. Knowing the risk associated with it, we offer a best-in-class solution to regulate your income and get the best return on investment. First, we help you find your goals and set out an alternative approach to achieving them. Then we help you create a plan to achieve your goals by investing intelligently.
                        </Typography>
                    </Grid>
                    {IconCommon(deposit)}
                </Grid >
                {cta('Book a free consultation with an independent wealth planner today.', 'Request a Call')}
            </div >
        </Grow >
    )
}

export default Howwehelp;