import React, { useEffect, useState } from 'react';
import { Button, Grid, Grow, IconButton, InputAdornment, makeStyles, TextField, Typography } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import emailjs from 'emailjs-com';
import GoogleMapReact from 'google-map-react';

import { init } from 'emailjs-com';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';
import MessageIcon from '@material-ui/icons/Message';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import CloseIcon from '@material-ui/icons/Close';

import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';

import logo from '../assets/logo.png';
import * as myConstants from './Constants';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const AnyReactComponent = ({ text }) => <Typography variant="subtitle2">{text}</Typography>;

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: `${theme.spacing(5)}px`
    },
    hov: {
        display: 'inline-block',
        cursor: 'default',
        backgroundImage: 'linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '20% 0.2em',
        backgroundPosition: '0 95%',
        transition: 'background-size 0.25s ease-in',
        '&:hover': {
            backgroundSize: '100% 88%'
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: '0px',
            fontSize: '3rem'
        }
    },
    backdrop: {
        cursor: 'default',
        backgroundImage: 'linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%)',
        backgroundRepeat: 'no-repeat',
        marginTop: theme.spacing(6),
    },
    rightPane: {
        // display: 'flex',
        // flexDirection: 'column',
        // margin: `${theme.spacing(8)}px ${theme.spacing(4)}px`,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '50vh',
        [theme.breakpoints.down('sm')]: {
            height: '30vh'
        }
    },
    contactEachItem: {
        display: 'flex',
        flexDirection: 'row',
        margin: theme.spacing(2),
        alignItems: 'center'
    },
    text: {
        fontWeight: '500',
        marginLeft: theme.spacing(2),
        height: 'auto',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            fontWeight: '100',
            fontSize: '0.9rem'
        }
    },
    icons: {
        fontSize: '2.8rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1.3rem'
        }
    },
    copyrights: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.7rem'
        }
    },
    leftPane: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '60vh',
        [theme.breakpoints.down('sm')]: {
            height: '40vh'
        }
    },
    innerCon: {
        justifyContent: 'flex-start',
    },
    logo: {
        width: 'auto',
        height: '100px',
        margin: `${theme.spacing(1)}px 0`
    },
    textfields: {
        // width: '90%',
        [theme.breakpoints.down('sm')]: {
            // width: '100%',
        }
    },
    writetous: {
        width: '100%',
        height: '45px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    },
    contact: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    },
    contactText: {
        marginBottom: theme.spacing(3)
    },
    marginIcon: {
        margin: theme.spacing(1),
    },
    flexCenter: {
        display: 'flex',
        justifyContent: 'center'
    },
    iconColor: {
        color: theme.palette.common.black,
        '&:hover': {
            color: 'gray',
        },
    },
    mapsGoogle: {
        height: '50vh',
        width: '100%',
        margin: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            width: '90%',
            height: '40vh',
        }
    }

}));

const Contact = () => {
    const classes = useStyles();

    useEffect(() => {
        init("user_KhT5BrRRk2tZK5Sd1t6lQ");
    }, []);

    const [open, setOpen] = React.useState(false);

    const [alertSeverity, setAlertSeverity] = useState('success');
    const [alertMessage, setAlertMessage] = useState('');

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const [formBody, setFormBody] = useState({
        customer_name: '',
        customer_number: '',
        customer_email: '',
        customer_message: '',
    });

    const [errorEmail, setErrorEmail] = useState(false);

    const handleChangeFormBody = (e) => {

        if (e.target.name === 'customer_number') {
            const onlyNums = e.target.value.replace(/[^0-9]/g, '');
            if (onlyNums.length < 10) {
                setFormBody({ ...formBody, [e.target.name]: onlyNums })
            } else if (onlyNums.length === 10) {
                const number = onlyNums.replace(
                    /(\d{5})(\d{5})/,
                    '+91-$1 $2'
                );
                setFormBody({ ...formBody, [e.target.name]: number })
            }
            return;
        }
        if (e.target.name === 'customer_email') {
            if (/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(e.target.value)) {
                setErrorEmail(false);
                setFormBody({ ...formBody, [e.target.name]: e.target.value })
                return;
            } else {
                if (e.target.value === '') {
                    setErrorEmail(false);
                } else {
                    setErrorEmail(true);
                }
                setFormBody({ ...formBody, [e.target.name]: e.target.value })
                return;
            }
        }

        setFormBody({ ...formBody, [e.target.name]: e.target.value })
    };

    const handleDeletePhone = (e) => {
        console.log(e);
        setFormBody({ ...formBody, customer_number: '' })
    }

    const handleSubmitForm = () => {
        if (!errorEmail && formBody.customer_email !== '' && formBody.customer_name !== '' && formBody.customer_number !== '' && formBody.customer_message !== '') {
            if (/[^0-9]/.test(formBody.customer_number)) {

                emailjs.send('service_arnxn5s', 'template_ibiew3k', formBody)
                    .then((result) => {
                        setOpen(true);
                        setAlertSeverity('success');
                        setAlertMessage('Your message has been successfully submitted!');
                        setFormBody({
                            customer_name: '',
                            customer_number: '',
                            customer_email: '',
                            customer_message: '',
                        });
                    }, (error) => {
                        console.log(error);
                        setOpen(true);
                        setAlertSeverity('error');
                        setAlertMessage('Network Issue! Please try again later.');
                        setFormBody({
                            customer_name: '',
                            customer_number: '',
                            customer_email: '',
                            customer_message: '',
                        });
                    });

            } else {
                setOpen(true);
                setAlertSeverity('error');
                setAlertMessage('Please provide a valid number');
            }
        } else {
            setOpen(true);
            setAlertSeverity('error');
            setAlertMessage('Please provide all details before submitting.');
        }
    };

    return (
        <Grow in>
            <div className={classes.root} id="contact-section">
                <Grid container justify="center" alignItems="center" style={{ height: '20vh' }}>
                    <Grid item>
                        <Typography variant="h2" component="div" className={classes.hov}>
                            Contact
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container justify="center" className={classes.contactText}>
                    <Grid item xs={10}>
                        <Typography variant="subtitle1" color="textSecondary" component="div">
                            If you like to discuss your financial needs, we'd be happy to help. Here are simpler ways for us to get in touch.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container justify="center" spacing={3} className={classes.contact}>
                    <Grid item xs={10} sm={10} md={5}>
                        <Grid container justify="space-between" spacing={3}>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    className={classes.textfields}
                                    id="write-name"
                                    name="customer_name"
                                    label="Name"
                                    variant="outlined"
                                    value={formBody.customer_name}
                                    onChange={handleChangeFormBody}
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <AccountCircleIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    className={classes.textfields}
                                    id="write-number"
                                    name="customer_number"
                                    label="Phone Number"
                                    variant="outlined"
                                    value={formBody.customer_number}
                                    onChange={handleChangeFormBody}
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <PhoneIcon />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            (formBody.customer_number !== '' ?
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleDeletePhone}
                                                    >
                                                        <CancelIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                                : null)
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    className={classes.textfields}
                                    id="write-email"
                                    name="customer_email"
                                    label="E-mail Id"
                                    variant="outlined"
                                    value={formBody.customer_email}
                                    onChange={handleChangeFormBody}
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <MailIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    error={errorEmail}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={10} sm={10} md={5}>
                        <Grid container justify="space-between" spacing={3}>
                            <Grid item xs={12} sm={12} md={12}>
                                <TextField
                                    className={classes.textfields}
                                    id="write-message"
                                    name="customer_message"
                                    label="Your Message"
                                    multiline
                                    rows={6}
                                    rowsMax={6}
                                    variant="outlined"
                                    value={formBody.customer_message}
                                    onChange={handleChangeFormBody}
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <MessageIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <Button variant="contained" color="primary" onClick={handleSubmitForm} className={classes.writetous} >Write to us</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <div className={classes.backdrop}>
                    <Grid container justify="center">
                        <Grid item xs={10} md={5}>
                            <div className={classes.leftPane}>
                                <img src={logo} alt="logo" className={classes.logo} />
                            <div className={classes.innerCon}>
                                <div className={classes.contactEachItem}>
                                    <PhoneIcon className={classes.icons} />
                                    <a href={`tel:${myConstants.SHORT_PHONE_NUMBER}`} style={{ cursor: 'pointer', textDecoration: 'none', color: 'inherit' }}>
                                        <Typography variant="subtitle1" component="div" gutterBottom className={classes.text}>
                                            {myConstants.SHORT_PHONE_NUMBER}
                                        </Typography>
                                    </a>
                                </div>
                                <div className={classes.contactEachItem}>
                                    <LocationOnIcon className={classes.icons} />
                                    <Typography variant="subtitle1" component="div" gutterBottom className={classes.text}>
                                        RMV Complex, Pensioner's Street, Palani Road, Dindigul.
                                    </Typography>
                                </div>
                                <div className={classes.contactEachItem}>
                                    <MailIcon className={classes.icons} />
                                    <a href="mailto: newhorizonwealth@gmail.com" style={{ cursor: 'pointer', textDecoration: 'none', color: 'inherit' }}>
                                        <Typography variant="subtitle1" component="div" gutterBottom className={classes.text}>
                                            newhorizonwealth@gmail.com
                                        </Typography>
                                    </a>
                                </div>
                            </div>
                            </div>
                        </Grid>
                        <Grid item xs={10} sm={10} md={5}>
                            <div className={classes.mapsGoogle}>
                                <GoogleMapReact
                                    bootstrapURLKeys={{ key: 'AIzaSyBJyn8r20Y78PGkOAIOMxntzV8FL6s6xNE' }}
                                    defaultCenter={{
                                        lat: 10.3907955516436,
                                        lng: 77.97204621545376
                                    }}
                                    yesIWantToUseGoogleMapApiInternals
                                    defaultZoom={15}
                                >
                                    <AnyReactComponent
                                        lat={10.3907955516436}
                                        lng={77.97204621545376}
                                        text="📌New Horizon Wealth Management Services"
                                    />
                                </GoogleMapReact>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container justify="center" alignItems="center" >
                        <Grid item xs={12} sm={12} md={4}></Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <div className={classes.flexCenter}>
                                <IconButton target="_blank" href={myConstants.FACEBOOK_URL} aria-label="facebook" className={classes.iconColor}>
                                    <FacebookIcon />
                                </IconButton>
                                <IconButton target="_blank" href={myConstants.INSTAGRAM_URL} aria-label="instagram" className={classes.iconColor}>
                                    <InstagramIcon />
                                </IconButton>
                                <IconButton target="_blank" href={myConstants.WHATSAPP_URL} aria-label="whatsapp" className={classes.iconColor}>
                                    <WhatsAppIcon />
                                </IconButton>
                                <IconButton target="_blank" href={myConstants.TWITTER_URL} aria-label="whatsapp" className={classes.iconColor}>
                                    <TwitterIcon />
                                </IconButton>
                                <IconButton target="_blank" href={myConstants.LINKEDIN_URL} aria-label="whatsapp" className={classes.iconColor}>
                                    <LinkedInIcon />
                                </IconButton>
                            </div>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4}>
                            <Typography variant="body2" gutterBottom className={classes.copyrights} style={{ textAlign: 'center' }}>
                                Copyright &#169; 2021 Newhorizon | All rights reserved.
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={open}
                    autoHideDuration={4000}
                    onClose={handleClose}
                    message="Note archived"
                    action={
                        <React.Fragment>
                            <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </React.Fragment>
                    }
                >
                    <Alert onClose={handleClose} severity={alertSeverity}>
                        {alertMessage}
                    </Alert>
                </ Snackbar>
            </div>
        </Grow >

    )
}

export default Contact
