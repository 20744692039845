import React from 'react';

import { Grid, Typography, makeStyles, Grow, Button } from '@material-ui/core';
import { Link } from "react-router-dom";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';

import * as myConstants from './Constants';

import mutualfund from '../assets/whatwedo/mutualfunds.jpg';
import realestate from '../assets/whatwedo/realestate.jpg';
import investment from '../assets/whatwedo/investments.jpg';
import insurance from '../assets/whatwedo/insurance.jpg';
import loan from '../assets/whatwedo/loan.jpg';
import deposit from '../assets/whatwedo/deposit.jpg';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: `${theme.spacing(3)}px`
    },
    hov: {
        display: 'inline-block',
        cursor: 'default',
        backgroundImage: 'linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '20% 0.2em',
        backgroundPosition: '0 95%',
        transition: 'background-size 0.25s ease-in',
        marginBottom: '5vh',
        '&:hover': {
            backgroundSize: '100% 88%'
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: '0px',
            fontSize: '3rem'
        }
    },
    gridContain: {
        height: 'auto',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            height: 'auto',
        }
    },
    content: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },
    contentText: {
        display: 'flex',
        justifyContent: 'flex-start',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center'
        }
    },
    contentText2: {
        display: 'flex',
        justifyContent: 'center',
        margin: theme.spacing(3)
    },
    text: {
        fontSize: '1.2rem',
        textAlign: 'justify',
        textJustify: 'inter-word',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
        }
    },
    consultation: {
        width: '80%',
        fontSize: '1.1rem',
        textAlign: 'center',
        height: '50px',
        transform: 'skew(340deg)',
        backgroundImage: 'linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.9rem',
            height: '65px',
        }
    },
    cta: {
        marginLeft: theme.spacing(2),
        fontWeight: 'bold',
        color: 'white',
        transform: 'skew(20deg)',
    },
    cardWidth: {
        maxWidth: 345,
    },
    cardHeight: {
        height: 140,
    },
    innerText: {
        textAlign: 'justify',
        textJustify: 'inter-word',
    },
    cardCenter: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
    }
}));

const WhatwedoPage = (props) => {

    const classes = useStyles({});

    const cardData = [
        {
            id: 1,
            title: 'Mutual Funds',
            message: 'Mutual Funds is a long journey with an unexpected twist and turns. We are right beside you to guide you to achieve long-term solid growth. Our expert team helps in choosing right fund schemes, investment options, and tracking methods.',
            img: mutualfund
        },
        {
            id: 2,
            title: 'Real Estate',
            message: 'Real estate is one of the dynamics, a most fragmented platform to handle, and a rapidly-growing sector. Our real estate offering serves the diverse need of property acquisition, purchasing, sales, attractive investment, and advisory services.',
            img: realestate
        },
        {
            id: 3,
            title: 'Investment Options',
            message: 'Our bespoken tactics and investment solutions are structured to give a consistent and solid growth in client wealth. By carefully considering clients\' financial stability, we are selective about investment recommendations, which minimize the risk and provide steady growth.',
            img: investment
        },
        {
            id: 4,
            title: 'Insurance',
            message: 'We must do everything possible to ensure our future. Insuring your life is one of the best ways toprotect your finances. It is our responsibility to assist you in finding the right insurance packageand in investing in money wisely, whether it is life, health, or two-wheeler insurance. No matteryour requirement, we make sure to make the insurance process simpler for you.',
            img: insurance
        },
        {
            id: 5,
            title: 'Loans',
            message: 'Don’t let money be the barrier to achieving your dreams. With an in-depth analysis, we come up with a best-in-class customized loan solution for all your needs. Right from personal loans to business loans, loans against properties to loans against shares, we simplify the process to give the shape for your dreams.',
            img: loan
        },
        {
            id: 6,
            title: 'Deposits',
            message: 'Deposits may sound easy but you need to have great insight in choosing the right destination and plans. Just rely on our expert for identifying the right plan. Knowing the risk associated with it, we offer a best-in-class solution to regulate your income and get the best return on investment.',
            img: deposit
        }
    ];

    const cardsLayout = (data) => {
        return <Card className={classes.cardWidth}>
            <CardActionArea>
                <CardMedia
                    component="img"
                    className={classes.cardHeight}
                    image={data.img || ''}
                    title=""
                />
                <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        {data.title || ''}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p" className={classes.innerText} >
                        {data.message || ''}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions>
                <Button component={Link} to="/Services" size="small" color="primary">
                    Learn More
                </Button>
            </CardActions>
        </Card>
    };

    return (
        <Grow in>
            <div className={classes.root} id="what-we-do">
                <Grid container justify="center" alignItems="center" style={{ height: '20vh' }}>
                    <Grid item>
                        <Typography variant="h2" component="div" className={classes.hov}>
                            What we do
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container justify="center" className={classes.gridContain}>
                    <Grid item xs={10} sm={9} md={9} className={classes.contentText}>
                        <Typography color="textSecondary" className={classes.text}>
                            We are committed to assisting you in your financial journey towards creating a bright future.
                            With fully- customized plans, we enable you to realize your future financial goals and make you
                            take a clear decision over them. A rightful investment option, profitable insurance plans, space to
                            invest in mutual funds, an insight into real estate, we have all of it for you.
                        </Typography>
                    </Grid>
                    {
                        cardData.map(
                            (cardsD) =>
                            (
                                <Grid item sm={6} md={3} key={cardsD.id} className={classes.contentText2}>
                                    {cardsLayout(cardsD)}
                                </Grid>
                            )
                        )
                    }
                    <Grid item sm={12} className={classes.content}>
                        <Typography className={classes.consultation} >
                            Take steps today to secure your tomorrow’s financial future.
                            Get Started!
                            <Button variant="contained" color="secondary" className={classes.cta} >
                                <a href={`tel:${myConstants.SHORT_PHONE_NUMBER}`} style={{ cursor: 'pointer', textDecoration: 'none', color: 'inherit' }}>
                                    Call us : {myConstants.SHORT_PHONE_NUMBER}
                                </a>
                            </Button>
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        </Grow>
    )
}

export default WhatwedoPage