import React from 'react';
import Helmet from 'react-helmet';

import { Grid, Typography, makeStyles, Grow, Hidden, Button } from '@material-ui/core';

import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import CategoryIcon from '@material-ui/icons/Category';
import CallSplitIcon from '@material-ui/icons/CallSplit';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import SquareFootIcon from '@material-ui/icons/SquareFoot';
import SpeedIcon from '@material-ui/icons/Speed';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAlt';
import PolymerIcon from '@material-ui/icons/Polymer';
import PolicyIcon from '@material-ui/icons/Policy';
import PhotoFilterIcon from '@material-ui/icons/PhotoFilter';

import { faQuoteLeft, faQuoteRight } from '@fortawesome/free-solid-svg-icons';

import svg10 from '../assets/svg/10.png';
import svg11 from '../assets/svg/11.svg';

import about1 from '../assets/aboutus/1.png';
import about3 from '../assets/aboutus/3.png';
import about4 from '../assets/aboutus/4.png';

import * as myConstants from './Constants';

import male from '../assets/aboutus/male.svg';
import female from '../assets/aboutus/female.svg';
import { TimelineOppositeContent } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: `${theme.spacing(1)}px`
    },
    aboutusHeading: {
        marginTop: `${theme.spacing(3)}px`,
        height: '20vh',
        [theme.breakpoints.down('sm')]: {
            marginTop: `${theme.spacing(1)}px`,
        }
    },
    hov: {
        display: 'inline-block',
        cursor: 'default',
        backgroundImage: 'linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '20% 0.2em',
        backgroundPosition: '0 95%',
        transition: 'background-size 0.25s ease-in',
        marginBottom: '5vh',
        '&:hover': {
            backgroundSize: '100% 88%'
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: '0px',
            fontSize: '3rem'
        }
    },
    hovSub: {
        display: 'inline-block',
        cursor: 'default',
        backgroundImage: 'linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '20% 0.2em',
        backgroundPosition: '0 95%',
        transition: 'background-size 0.25s ease-in',
        marginBottom: '1vh',
        '&:hover': {
            backgroundSize: '100% 88%'
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: '0px',
            fontSize: '2rem'
        }
    },
    svg: {
        margin: theme.spacing(2),
        height: '150px',
        width: 'auto',
        [theme.breakpoints.down('sm')]: {
            height: '100px',
            width: 'auto',
        }
    },
    gridContain: {
        height: 'auto',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            height: 'auto',
        }
    },
    gridContain2: {
        height: '70vh',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            height: 'auto',
        }
    },
    content: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },
    content2: {
        // maxWidth: '550px',
        fontStyle: 'italic',
        margin: theme.spacing(2),
        textAlign: 'justify'
    },
    consultation: {
        width: '80%',
        fontSize: '1.1rem',
        textAlign: 'center',
        height: '60px',
        transform: 'skew(340deg)',
        backgroundImage: 'linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.9rem',
        }
    },
    cta: {
        marginLeft: theme.spacing(2),
        fontWeight: 'bold',
        color: 'white',
        transform: 'skew(20deg)',
    },
    innerText: {
        textAlign: 'justify',
        textJustify: 'inter-word',
    },
    text: {
        textAlign: 'justify',
        textJustify: 'inter-word'
    },
    gridSpacing: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    svgs: {
        height: '250px',
        margin: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            height: '100px'
        }
    },
    nameTag: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center'
    },
    vertLine: {
        display: 'flex',
        justifyContent: 'center',
        borderLeft: '3px solid #009624',
        borderBottom: 0,
        height: '50%',
        width: 'auto',
        position: 'absolute',
        transition: 'all 0.8s ease-in',
        // left: '49.5%',
        [theme.breakpoints.down('sm')]: {
            borderLeft: 0,
            height: 0,
            width: '50%',
            position: 'absolute',
            borderBottom: '3px solid #009624',
        }
    },
    faicon: {
        fontSize: '10px',
        margin: '0 10px 10px 0',

    },
    paper: {
        padding: '6px 16px',
    },
    secondaryTail: {
        color: theme.palette.secondary.main,
    },
    flexCenter: {
        display: 'flex',
        justifyContent: 'center'
    },
}));

const AboutUs = (props) => {

    const classes = useStyles({});

    const whyHorizon = [
        {
            name: 'Our Values',
            icon: <AssignmentTurnedInIcon className={classes.secondaryTail} />
        },
        {
            name: 'Passion on what we do',
            icon: <CategoryIcon className={classes.secondaryTail} />
        },
        {
            name: 'Excellence in handling clients and colleagues',
            icon: <CallSplitIcon className={classes.secondaryTail} />
        },
        {
            name: 'Mindful workspace',
            icon: <EmojiObjectsIcon className={classes.secondaryTail} />
        },
        {
            name: 'Integrity in services',
            icon: <SquareFootIcon className={classes.secondaryTail} />
        },
        {
            name: 'Diverse Investment Solution',
            icon: <PolicyIcon className={classes.secondaryTail} />
        },
        {
            name: 'One-on-One Service',
            icon: <SentimentSatisfiedAltIcon className={classes.secondaryTail} />
        },
        {
            name: 'Ingenuity',
            icon: <PolymerIcon className={classes.secondaryTail} />
        },
        {
            name: 'Highly Qualified Professionals',
            icon: <SupervisedUserCircleIcon className={classes.secondaryTail} />
        },
        {
            name: 'Customized investment plans',
            icon: <PhotoFilterIcon className={classes.secondaryTail} />
        },
        {
            name: 'Expert advisory Services',
            icon: <SpeedIcon className={classes.secondaryTail} />
        }
    ];

    const aboutIconCommon = () => {
        return <Grid item xs={11} sm={10} md={4} className={classes.contentText}>
            <div className={classes.flexCenter}>
                <img src={about4} alt="img" className={classes.svg} />
            </div>
        </Grid>
    };


    // React.useEffect(() => {
    //     document.title = 'Wealth Management Advisor in Dindigul | Mutual Fund Financial Advisor in Dindigul';
    //     document.description = 'New Horizon is the best wealth management services in Dindigul. We provide wealth management advisor services in Dindigul, mutual fund financial advisor in Dindigul, mutual fund advisory services in Dindigul.';
    //     document.keywords = 'Wealth Management Advisor in Dindigul, Mutual Fund Financial Advisor in Dindigul, Mutual Fund Advisory Services in Dindigul, Portfolio Management Services in Dindigul';
    // }, []);

    return (
        <>
            <Helmet>
                <title>Wealth Management Advisor in Dindigul | Mutual Fund Financial Advisor in Dindigul</title>
                <meta name='description' content='New Horizon is the best wealth management services in Dindigul. We provide wealth management advisor services in Dindigul, mutual fund financial advisor in Dindigul, mutual fund advisory services in Dindigul.' />
                <meta name='keywords' content='Wealth Management Advisor in Dindigul, Mutual Fund Financial Advisor in Dindigul, Mutual Fund Advisory Services in Dindigul, Portfolio Management Services in Dindigul' />
                <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                <link rel="canonical" href="https://www.newhorizonwealth.in/about" />
                <meta property="og:locale" content="en_IN" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Wealth Management Advisor in Dindigul | Mutual Fund Financial Advisor in Dindigul" />
                <meta property="og:description" content="New Horizon is the best wealth management services in Dindigul. We provide wealth management advisor services in Dindigul, mutual fund financial advisor in Dindigul, mutual fund advisory services in Dindigul." />
                <meta property="og:url" content="https://www.newhorizonwealth.in/about" />
                <meta property="og:site_name" content="New Horizon" />
                <meta property="og:image" content="https://www.newhorizonwealth.in/static/media/logo.c8d9e4cd.png" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:label1" content="Written by" />
                <meta name="twitter:data1" content="New Horizon" />
                <meta name="twitter:label2" content="Est. reading time" />
                <meta name="twitter:data2" content="2 minutes" />
            </Helmet>
            <Grow in>
                <div className={classes.root} id="what-we-do">
                    <Grid container justify="center" alignItems="center" style={{ height: '20vh' }}>
                        <Grid item>
                            <Typography variant="h2" component="div" className={classes.hov}>
                                About Us
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justify="center" spacing={2}>
                        <Grid item xs={11} sm={10} md={10} className={classes.contentText}>
                            <div className={classes.flexCenter}>
                                <img src={about1} alt="img" className={classes.svgs} />
                            </div>
                        </Grid>
                        <Grid item xs={10} sm={9} md={9} className={classes.contentText}>
                            <Typography variant="subtitle1" color="textSecondary" className={classes.text}>
                                First and foremost, we want a stable financial future for our families. Yet many of us do not properly manage our wealth, since we are unsure how to progress it, or perhaps we have not prioritized financial success. Our purpose is to help you create a financially successful future, and that is why we founded New Horizons.
                            </Typography>
                        </Grid>
                        <Grid item xs={10} sm={9} md={9} className={classes.contentText}>
                            <Typography variant="subtitle1" color="textSecondary" className={classes.text}>
                                We at New Horizons put together bundles of financial strategies to help you reach your financial goals. In New Horizons, we guarantee you will achieve all your family's financial dreams and remain financially secure.
                            </Typography>
                        </Grid>
                        <Grid item xs={10} sm={10} md={4} className={classes.contentText}>
                            <div className={classes.flexCenter}>
                                <img src={about3} alt="img" className={classes.svg} />
                            </div>
                        </Grid>
                        <Grid item xs={10} sm={10} md={5} className={classes.contentText}>
                            <Typography variant="subtitle1" color="textSecondary" className={classes.text}>
                                New Horizons has just begun its journey with a well-qualified team of professionals experienced in handling financial matters. It's our mission is to transform managing money and ensuring people have a financially free future through our solutions. Our advisors are CSM NISM-certified and have years of experience providing high-quality financial advice.
                            </Typography>
                            <Typography variant="subtitle1" color="textSecondary" className={classes.text}>
                                With a wide range of services such as Mutual Funds, a wide range of Investment Options, Real Estate Services, Loan Options, Insurance, and Deposit Options, we strive to help you achieve financial success.
                            </Typography>
                        </Grid>
                        <Hidden mdUp>
                            {aboutIconCommon()}
                        </Hidden>
                        <Grid item xs={10} sm={10} md={5} className={classes.contentText}>
                            <Typography variant="subtitle1" color="textSecondary" className={classes.text}>
                                We at New Horizons analyze every aspect of the financial status, and we know how difficult it is to manage money correctly so as to create a secure future. To ensure that our clients' needs are met, our team goes the extra mile, which focuses on their success. We make your life easier with our disciplined, transparent, and adapted financial solutions.
                            </Typography>
                            <Typography variant="subtitle1" color="textSecondary" className={classes.text}>
                                Remember, we are more than a financial planner; our services are meant to be a combination of wealth management and planning.
                            </Typography>
                        </Grid>
                        <Hidden smDown>
                            {aboutIconCommon()}
                        </Hidden>
                    </Grid>
                    <Grid container justify="center" alignItems="center" style={{ height: '20vh' }}>
                        <Grid item>
                            <Typography variant="h4" component="div" className={classes.hovSub}>
                                Meet some members of our Team
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justify="center" spacing={2}>
                        <Grid item xs={10} sm={9} md={9} className={classes.contentText}>
                            <Typography variant="subtitle1" color="textSecondary" className={classes.text}>
                                People at new Horizon thrive in dynamic environments and offer clients a variety of unique and smart solutions tailored to their specific needs. Our investment philosophy is based on prudence and discretion, which are the pillars of any good wealth management.
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justify="center" spacing={2}>
                        <Grid item xs={11} sm={10} md={5} className={classes.contentText}>
                            <div className={classes.nameTag}>
                                <img src={female} alt="female" className={classes.svg} />
                                <Typography variant="h6" color="textSecondary" className={classes.text}>
                                    Nisha Devi
                                </Typography>
                            </div>
                        </Grid>
                        <Grid item xs={11} sm={10} md={5} className={classes.contentText}>
                            <div className={classes.nameTag}>
                                <img src={male} alt="male" className={classes.svg} />
                                <Typography variant="h6" color="textSecondary" className={classes.text}>
                                    Ramesh
                                </Typography>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container justify="center" alignItems="center" className={classes.aboutusHeading}>
                        <Grid item>
                            <Typography variant="h2" component="div" className={classes.hov}>
                                Our Mission & Vision
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justify="center" alignItems="center" alignContent="center" className={classes.gridContain2} spacing={2}>
                        <Grid item xs={10} sm={9} md={5}>
                            <div>
                                <div className={classes.gridSpacing}>
                                    <img src={svg10} alt="img" className={classes.svgs} />
                                </div>
                                <div>
                                    <Typography variant="body1" className={classes.content2}>
                                        <span>
                                            <FontAwesomeIcon icon={faQuoteLeft} className={classes.faicon} />
                                            With the right insight into what wealth meant for people and how it impacts the future, we are here to help people invest and plan the financial future through our actions. To reinvent how people manage their wealth, future investment, and financial knowledge. We strive to live up to the client's expectations.
                                            <FontAwesomeIcon icon={faQuoteRight} className={classes.faicon} style={{ margin: '0 0px 10px 12px' }} />
                                        </span>
                                    </Typography>
                                </div>
                            </div>
                        </Grid>
                        <Grid item style={{ display: 'contents' }}>
                            <div className={classes.vertLine}></div>
                        </Grid>
                        <Grid item xs={10} sm={9} md={5}>
                            <div className={classes.gridSpacing}>
                                <div>
                                    <img src={svg11} alt="img" className={classes.svgs} />
                                </div>
                                <div>
                                    <Typography variant="body1" className={classes.content2}>
                                        <span>
                                            <FontAwesomeIcon icon={faQuoteLeft} className={classes.faicon} />
                                            We work hard to become the most trusted financial advisor in India by inspiring people to believe in a healthy financial future and create an innovative solution to secure and widen the financial boundaries of any induvial.
                                            <FontAwesomeIcon icon={faQuoteRight} className={classes.faicon} style={{ margin: '0 0px 10px 12px' }} />
                                        </span>
                                    </Typography>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container justify="center" alignItems="center" className={classes.aboutusHeading}>
                        <Grid item>
                            <Typography variant="h2" component="div" className={classes.hov}>
                                Why New Horizon
                            </Typography>
                        </Grid>
                    </Grid>
                    <Hidden smDown>
                        <Grid container justify="center" alignItems="center" alignContent="center" className={classes.gridContain} spacing={2}>
                            <Grid item xs={11} sm={10} md={8} >
                                <Timeline align="alternate">
                                    {
                                        whyHorizon.map((why, index) => (
                                            <TimelineItem key={index}>
                                                {/* <TimelineOppositeContent>
                                    <Typography variant="body2" color="textSecondary">
                                    9:30 am
                                    </Typography>
                                </TimelineOppositeContent> */}
                                                <TimelineSeparator>
                                                    <TimelineDot color="primary" variant="outlined">
                                                        {why.icon}
                                                    </TimelineDot>
                                                    {index === (whyHorizon.length - 1) ? null : <TimelineConnector />}
                                                </TimelineSeparator>
                                                <TimelineContent>
                                                    {/* <Paper elevation={3} className={classes.paper}>
                                            Typography variant="h6" component="h1">
                                                {why.name}
                                                </Typography>
                                            <Typography>{why.name}</Typography>
                                        </Paper> */}
                                                    <Typography variant="h6" color="textSecondary">{why.name}</Typography>
                                                </TimelineContent>
                                            </TimelineItem>
                                        ))
                                    }
                                </Timeline>
                            </Grid>
                        </Grid>
                    </Hidden>
                    <Hidden mdUp>
                        <Grid container className={classes.gridContain} spacing={2}>
                            <Grid item xs={11} sm={10} md={8} >
                                <Timeline align="left">
                                    {
                                        whyHorizon.map((why, index) => (
                                            <TimelineItem key={index}>
                                                <TimelineOppositeContent style={{ flex: 0 }} />
                                                <TimelineSeparator>
                                                    <TimelineDot color="primary" variant="outlined">
                                                        {why.icon}
                                                    </TimelineDot>
                                                    {index === (whyHorizon.length - 1) ? null : <TimelineConnector />}
                                                </TimelineSeparator>
                                                <TimelineContent>
                                                    {/* <Paper elevation={3} className={classes.paper}>
                                            Typography variant="h6" component="h1">
                                                {why.name}
                                                </Typography>
                                                <Typography>{why.name}</Typography>
                                        </Paper> */}
                                                    <Typography variant="subtitle2" color="textSecondary">{why.name}</Typography>
                                                </TimelineContent>
                                            </TimelineItem>
                                        ))
                                    }
                                </Timeline>
                            </Grid>
                        </Grid>
                    </Hidden>
                    <Grid container justify="center" className={classes.gridContain}>
                        <Grid item sm={12} className={classes.content}>
                            <Typography className={classes.consultation} >
                                Enquire today to make tomorrow a better and risk-free financial future.
                                <Button variant="contained" color="secondary" className={classes.cta} >
                                    <a href={`tel:${myConstants.SHORT_PHONE_NUMBER}`} style={{ cursor: 'pointer', textDecoration: 'none', color: 'inherit' }}>
                                        Call us : {myConstants.SHORT_PHONE_NUMBER}
                                    </a>
                                </Button>
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            </Grow >
        </>
    )
}

export default AboutUs