import * as React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import errorImg from '../assets/error/404error.svg'
import { Helmet } from 'react-helmet';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            // height: 'calc(100vh - 13px)'
            backgroundColor: '#50D6D9'
        },
        innerText: {
            backgroundColor: '#50D6D9',
            // height: '100vh'
        },
        center: {
            height: '100vh',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
        },
        image: {
            maxHeight: '40vh'
        },
        text: {
            fontWeight: 300,
            color: '#fff',
            textAlign: 'center'
        },
        paragraph: {
            color: '#fff',
            textAlign: 'center'
        }
    }),
);

const ErrorPage = () => {
    const classes = useStyles({});
    return (
        <>
            <Helmet>
                <title>Page not found</title>
            </Helmet>
            <div className={classes.root}>
                <CssBaseline />
                <Container fixed>
                    <div className={classes.innerText}>
                        <div className={classes.center}>
                            <img src={errorImg} alt="error page" className={classes.image} />
                            <Typography variant="h3" gutterBottom className={classes.text}>
                                Whoops! Page not found
                            </Typography>
                            <Typography variant="body2" gutterBottom className={classes.paragraph}>
                                We are very sorry for the inconvenience. <br />It looks like you're trying to access a page that has been deleted or never even existed.
                            </Typography>
                        </div>
                    </div>
                </Container>
            </div>
        </>
    );
};

export default ErrorPage;
