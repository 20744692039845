export const PHONE_NUMBER = '+91 9790581123';
export const SHORT_PHONE_NUMBER = '9790581123';
export const NAV_LINKS = [
    {
        name: 'Home',
        link: '/',
        subLink: [
            {
                id: 'products-section',
                name: 'Products'
            },
            {
                id: 'mission-vision-section',
                name: 'Mission & Vision'
            },
            {
                id: 'what-we-do',
                name: 'What we Do'
            },
            {
                id: 'contact-section',
                name: 'Contact'
            }
        ]
    },
    {
        name: 'Services',
        link: '/services',
        subLink: [
            {
                id: 'contact-section',
                name: 'Contact'
            }
        ]
    },
    {
        name: 'About Us',
        link: '/about',
        subLink: [
            {
                id: 'contact-section',
                name: 'Contact'
            }
        ]
    }
];
export const FACEBOOK_URL = "https://www.facebook.com/newhorizonwealthmanagementservice";
export const INSTAGRAM_URL = "https://instagram.com/newhorizon_wealth";
export const WHATSAPP_URL = `https://api.whatsapp.com/send/?phone=+91${SHORT_PHONE_NUMBER}&text=Hi`;
export const TWITTER_URL = `https://twitter.com/planner_wealth`;
export const LINKEDIN_URL = `https://www.linkedin.com/in/new-horizon-358374214`;
export const BANNER_TEXT = ``;