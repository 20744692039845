import React from 'react';

import { Grid, Typography, makeStyles, Grow, Button } from '@material-ui/core';

import * as myConstants from './Constants';

import how1 from '../assets/howwedo/1.svg';
import how2 from '../assets/howwedo/2.svg';
import how3 from '../assets/howwedo/3.svg';
import how4 from '../assets/howwedo/4.svg';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: `${theme.spacing(3)}px`
    },
    hov: {
        display: 'inline-block',
        cursor: 'default',
        backgroundImage: 'linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '20% 0.2em',
        backgroundPosition: '0 95%',
        transition: 'background-size 0.25s ease-in',
        marginBottom: '5vh',
        '&:hover': {
            backgroundSize: '100% 88%'
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: '0px',
            fontSize: '3rem'
        }
    },
    gridContain: {
        height: 'auto',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            height: 'auto',
        }
    },
    content: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },
    contentText: {
        display: 'flex',
        justifyContent: 'flex-start',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center'
        }
    },
    contentText2: {
        display: 'flex',
        justifyContent: 'center',
        margin: theme.spacing(3)
    },
    text: {
        fontSize: '1.2rem',
        textAlign: 'justify',
        textJustify: 'inter-word',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
        }
    },
    consultation: {
        width: '80%',
        fontSize: '1.1rem',
        textAlign: 'center',
        height: '50px',
        transform: 'skew(340deg)',
        backgroundImage: 'linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.9rem',
            height: '65px',
        }
    },
    cta: {
        marginLeft: theme.spacing(2),
        fontWeight: 'bold',
        color: 'white',
        transform: 'skew(20deg)',
    },
    innerText: {
        textAlign: 'justify',
        textJustify: 'inter-word',
    },
    flexCenter: {
        display: 'flex',
        justifyContent: 'center'
    },
    flexStart: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    hovSub: {
        display: 'inline-block',
        cursor: 'default',
        backgroundImage: 'linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '20% 0.2em',
        backgroundPosition: '0 95%',
        transition: 'background-size 0.25s ease-in',
        marginBottom: '1vh',
        '&:hover': {
            backgroundSize: '100% 88%'
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: '0px',
            fontSize: '2rem'
        }
    },
    svg: {
        margin: theme.spacing(2),
        height: '150px',
        width: 'auto',
        [theme.breakpoints.down('sm')]: {
            height: '100px',
            width: 'auto',
        }
    },
}));

const Howwediffer = (props) => {

    const classes = useStyles({});

    const contentData = [
        {
            title: 'Team of Qualified Professionals ',
            content: 'When it comes to money, we need the best experts in the field to manage it. No matter where you are based or what financial service needs you may have, we are committed to providing a superior experience for you. Our people have all the Skillset to handle all the financial world that value most to you. Financial professionals at our firm have expertise in a wide range of areas.',
            img: how1
        },
        {
            title: 'Innovative Solutions',
            content: 'It is impossible to solve every problem using the simple and same approach every time. Certain situations require thinking outside of the box, and our experts excel at solving them. Using our innovative financial planning, we help clients attain both short-term and long-term financial goals. ',
            img: how2
        },
        {
            title: 'Specific Financial Solution/ One-On-One Solutions ',
            content: 'We are all individuals who have unique needs and face unfamiliar situations. Just a common financial solution can\'t site all the client needs or solve their problem. Depending on the client\'s personal needs and priorities, we develop a plan to help them achieve their financial goals. All of our services focus on the goals that you have, what you want to accomplish, and how we can better the financial situation.',
            img: how3
        },
        {
            title: 'Integrity and Transparency',
            content: 'At New Horizons, we ensure the highest level of ethical conduct among our people. We have learned to deliver service with integrity, diligence, transparency, and civility. This value holds a prominent place in our firm and we enforce it consistently.',
            img: how4
        },
    ];

    const contentDesigned = () => {
        return contentData.map((content, index) => {
            return (
                <Grid key={index} container justify="center" alignItems="center" className={classes.gridContain}>
                    <Grid item xs={10} sm={9} md={9} className={`${classes.contentText2} ${classes.flexStart}`}>
                        <Typography variant="h4" component="div" className={classes.hovSub}>
                            {content.title}
                        </Typography>
                    </Grid>
                    <Grid item xs={10} sm={9} md={3} className={classes.contentText2}>
                        <div className={classes.flexCenter}>
                            <img src={content.img} alt="img" className={classes.svg} />
                        </div>
                    </Grid>
                    <Grid item xs={10} sm={9} md={6} className={classes.contentText2}>
                        <Typography color="textSecondary" className={classes.text}>
                            {content.content}
                        </Typography>
                    </Grid>
                </Grid>
            )
        })
    };

    return (
        <Grow in>
            <div className={classes.root} id="what-we-do">
                <Grid container justify="center" alignItems="center" style={{ height: '20vh' }}>
                    <Grid item>
                        <Typography variant="h2" component="div" className={classes.hov}>
                            How We Differ from Others
                        </Typography>
                    </Grid>
                </Grid>
                {contentDesigned()}
                <Grid container justify="center" className={classes.gridContain}>
                    <Grid item sm={12} className={classes.content}>
                        <Typography className={classes.consultation} >
                            Enquire today to make tomorrow a better and risk-free financial future.
                            <Button variant="contained" color="secondary" className={classes.cta} >
                                <a href={`tel:${myConstants.SHORT_PHONE_NUMBER}`} style={{ cursor: 'pointer', textDecoration: 'none', color: 'inherit' }}>
                                    Call us : {myConstants.SHORT_PHONE_NUMBER}
                                </a>
                            </Button>
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        </Grow>
    )
}

export default Howwediffer