import React from 'react';

import { Grid, Typography, makeStyles, Grow, Button } from '@material-ui/core';

import * as myConstants from './Constants';

import process1 from '../assets/wealthmanagmentprocess/process1.png';
import process2 from '../assets/wealthmanagmentprocess/process2.png';
import process3 from '../assets/wealthmanagmentprocess/process3.png';
import process4 from '../assets/wealthmanagmentprocess/process4.png';
import process5 from '../assets/wealthmanagmentprocess/process5.png';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: `${theme.spacing(3)}px`
    },
    svg: {
        height: '140px',
        width: 'auto',
        [theme.breakpoints.down('sm')]: {
            height: '110px',
            width: 'auto',
        }
    },
    hov: {
        display: 'inline-block',
        cursor: 'default',
        backgroundImage: 'linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '20% 0.2em',
        backgroundPosition: '0 95%',
        transition: 'background-size 0.25s ease-in',
        marginBottom: '5vh',
        '&:hover': {
            backgroundSize: '100% 88%'
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: '0px',
            fontSize: '3rem'
        }
    },
    gridContain: {
        height: 'auto',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            height: 'auto',
        }
    },
    content: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },
    consultation: {
        width: '80%',
        fontSize: '1.1rem',
        textAlign: 'center',
        height: '50px',
        transform: 'skew(340deg)',
        backgroundImage: 'linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.9rem',
            height: '65px',
        }
    },
    cta: {
        marginLeft: theme.spacing(2),
        fontWeight: 'bold',
        color: 'white',
        transform: 'skew(20deg)',
    },
    innerText: {
        textAlign: 'justify',
        textJustify: 'inter-word',
    },
    processContainer: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(5),
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1)
    },
    proessImg: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        }
    }
}));

const WealthPlanningProcess = (props) => {

    const classes = useStyles({});

    const processes = [
        {
            id: 1,
            title: 'Knowing you',
            message: 'Our initial inquiry focuses mainly on your current financial situation and your future financial goals, as well as how you manage your wealth. We also gain a deeper understanding of each other and can best serve you. Analyzing how we can help you improve your financial situation.',
            img: process1
        },
        {
            id: 2,
            title: 'Discovering Financial Goals',
            message: 'Individuals have different life goals, so our in-depth analysis will give us a better idea of what your financial objectives are. We discuss what matters most to you, as well as your short- and long-term financial objectives. We prioritize your goals based on your objectives and financial concerns.',
            img: process2
        },
        {
            id: 3,
            title: 'Developing Financial Plan for You',
            message: 'Crafting financial plans tailored to your current financial situation and future goals. Our expert\'s strategy will go through all uncertain situations and guide you to financial success. We provide a clear and concise explanation of the financial planning recommendation and its supporting reasoning to the client to make their informed decision.',
            img: process3
        },
        {
            id: 4,
            title: 'Embarking Your Financial journey',
            message: 'It is your choice to change the plan in real-time to incorporate potential goals or needs, and now it is time to get to work. At every stage, our team will support you and guide you to make exceptional decisions to accomplish your goals. Our team makes sure that plan is executed successfully and manages your financial future according to your needs.',
            img: process4
        },
        {
            id: 5,
            title: 'Monitoring progress ',
            message: 'Besides continuously monitoring your wealth, we conduct a review at regular intervals to assess your changing needs, including your goals, risk profile, and lifestyle. We can never avoid changes in anyone\'s life, which is why we made it easy for you to update the plans. As per your convenience, we can update the finances to meet your new demand and goals.',
            img: process5
        }
    ];

    return (
        <Grow in>
            <div className={classes.root} id="what-we-do">
                <Grid container justify="center" alignItems="center" style={{ height: '20vh' }}>
                    <Grid item>
                        <Typography variant="h2" component="div" className={classes.hov}>
                            Our Wealth Planning Process
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container justify="center" className={classes.gridContain} spacing={2}>
                    <Grid item xs={10} sm={10} md={9}>
                        {
                            processes.map((process) => (
                                <Grid container justify="space-between" alignItems="center" spacing={1} key={process.id} className={classes.processContainer}>
                                    <Grid item xs={11} sm={10} md={1}>
                                        <div className={classes.proessImg}>
                                            <img src={process.img} alt={process.title} className={classes.svg} />
                                        </div>
                                    </Grid>
                                    <Grid item xs={11} sm={10} md={10}>
                                        <Typography variant="subtitle1" color="textSecondary" component="p" className={classes.innerText} >
                                            {process.message || ''}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12}>
                                    </Grid>
                                </Grid>
                            ))
                        }
                    </Grid>
                </Grid>
                <Grid container justify="center" className={classes.gridContain}>
                    <Grid item sm={12} className={classes.content}>
                        <Typography className={classes.consultation} >
                            Get a bit of advice to make your money work better for you.
                            <Button variant="contained" color="secondary" className={classes.cta} >
                                <a href={`tel:${myConstants.SHORT_PHONE_NUMBER}`} style={{ cursor: 'pointer', textDecoration: 'none', color: 'inherit' }}>
                                    Call us : {myConstants.SHORT_PHONE_NUMBER}
                                </a>
                            </Button>
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        </Grow>
    )
}

export default WealthPlanningProcess;