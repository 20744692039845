import React from 'react';

import { AppBar, Grid, makeStyles, Toolbar, Hidden, Typography, Grow, IconButton, MenuItem, Menu, Button } from '@material-ui/core';
import { Link } from "react-router-dom";

import * as myConstants from './Constants';
import logo from '../assets/logo.png';

import MenuIcon from '@material-ui/icons/Menu';
import PhoneInTalkIcon from '@material-ui/icons/PhoneInTalk';

const useStyles = makeStyles((theme) => ({

    root: {
        background: 'linear-gradient(90deg, rgba(0,191,150,100) 0%, rgba(139,195,74,1) 100%)'
    },
    logo: {
        width: 'auto',
        height: '50px',
        margin: `${theme.spacing(1)}px 0`,
        [theme.breakpoints.down('sm')]: {
            height: '40px',
        }
    },
    logoMobile: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'flex-start'
        }
    },
    navEn: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    nav: {
        display: 'flex',
        justifyContent: 'flex-end',
        // marginRight: '50px',
        [theme.breakpoints.down('sm')]: {
            marginRight: theme.spacing(1),
        }
    },
    navLink: {
        textDecoration: 'none',
        cursor: 'pointer'
    },
    navDef: {
        margin: '0 10px',
        color: theme.palette.primary.contrastText,
        cursor: 'pointer',
        fontWeight: 'bold',
        position: 'relative',
        '&:hover': {
            color: '#ffff',
            transition: 'all 0.3s ease-in-out'
        },
        '&::before': {
            content: '""',
            position: 'absolute',
            width: '100%',
            height: '2px',
            bottom: '0',
            left: '0',
            backgroundColor: '#FFF',
            visibility: 'hidden',
            transform: 'scaleX(0)',
            transition: 'all 0.3s ease-in-out'
        },
        '&:hover::before': {
            visibility: 'visible',
            transform: 'scaleX(0.9)'
        }
    },
    menuItem: {
        margin: theme.spacing(1)
    },
    menuBut: {
        // marginLeft: theme.spacing(1)
    },
    ctaEn: {
        display: 'flex',
        justifyContent: 'center',
    },
    cta: {
        marginLeft: theme.spacing(2),
        fontWeight: 'bold',
        color: 'white',
        // backgroundColor: '#8CDCE3'
    }

}));

const Header = (props) => {

    const classes = useStyles({});

    const [menuAnchor, setMenuAnchor] = React.useState(null);
    const [subLinkAnchor, setSubLinkAnchor] = React.useState(null);
    // const [sublinks, setSublinks] = React.useState([]);

    const onClickMenu = (event) => {
        setMenuAnchor(event.currentTarget);
    };
    const onCloseMenu = () => {
        setMenuAnchor(null);
    };

    // const onClickSubLink = (event, navItem) => {
    //     if (Array.isArray(navItem.subLink) && navItem.subLink.length > 0) {
    //         setSublinks(navItem.subLink);
    //         setSubLinkAnchor(event.currentTarget);
    //     } else {
    //         setSublinks([]);
    //     }
    // };
    const onCloseSubLink = () => {
        setSubLinkAnchor(null);
    };

    const navLinks = myConstants.NAV_LINKS;

    // const handleCliclNavs = (id) => {

    //     const element = document.querySelector(`#${id}`);
    //     element && element.scrollIntoView({ behavior: 'smooth', block: 'center' });

    //     setTimeout(() => {
    //         setMenuAnchor(null);
    //     }, 1000);

    // };
    return (
        <Grow in>
            <AppBar position="sticky" className={classes.root}>
                <Toolbar>
                    <Grid container spacing={1} justifycontent="center" alignItems="center">
                        <Grid item xs={5} sm={6} md={4} className={classes.logoMobile}>
                            <Link to="/" className={classes.navLink}>
                                <img src={logo} alt="logo" className={classes.logo} />
                            </Link >
                        </Grid>
                        <Hidden smDown>
                            <Grid item md={5} className={classes.navEn}>
                                <div className={classes.nav}>
                                    {/* {
                                        navItems.map((navItem, index) => (
                                            <Typography key={index} variant="subtitle1" className={classes.navDef} onClick={() => handleCliclNavs(navItem.id)}>
                                                {navItem.name}
                                            </Typography>
                                        ))
                                    } */}
                                    {
                                        navLinks.map((navItem, index) => (
                                            <Link key={index} to={navItem.link} className={classes.navLink}>
                                                <Typography key={index} variant="subtitle1" className={classes.navDef}>
                                                {/* <Typography key={index} variant="subtitle1" onMouseOver={(e) => onClickSubLink(e, navItem)} onClick={(e) => e.preventDefault()} className={classes.navDef}> */}
                                                    {navItem.name}
                                                </Typography>
                                            </Link >
                                        )
                                        )
                                    }
                                </div>
                            </Grid>
                            <Grid item sm={3} md={3} className={classes.ctaEn}>
                                <Button variant="outlined" href={`tel:${myConstants.SHORT_PHONE_NUMBER}`} startIcon={<PhoneInTalkIcon />} className={classes.cta}>{myConstants.PHONE_NUMBER}</Button>
                            </Grid>
                        </Hidden>
                        <Hidden mdUp>
                            <Grid item xs={6} sm={5} md={3} className={classes.ctaEn}>
                                <Button variant="outlined" href={`tel:${myConstants.SHORT_PHONE_NUMBER}`} startIcon={<PhoneInTalkIcon />} className={classes.cta}>{myConstants.SHORT_PHONE_NUMBER}</Button>
                            </Grid>
                            <Grid item xs={1}>
                                <div className={classes.nav}>
                                    <IconButton edge="end" color="inherit" aria-label="menu" onClick={onClickMenu} className={classes.menuBut}>
                                        <MenuIcon />
                                    </IconButton>
                                </div>
                            </Grid>
                        </Hidden>
                    </Grid>
                    <Menu
                        id="simple-menu"
                        anchorEl={menuAnchor}
                        open={Boolean(menuAnchor)}
                        onClose={onCloseMenu}

                    >
                        {
                            navLinks.map((navItem, index) => (
                                <MenuItem key={index} component={Link} to={navItem.link} className={classes.menuItem}>
                                    {/* <Link to={navItem.link} className={classes.navLink}> */}
                                        {navItem.name}
                                    {/* </Link > */}
                                </MenuItem>
                            )
                            )
                        }
                    </Menu>
                    <Menu
                        id="simple-menu"
                        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                        transformOrigin={{ vertical: "top", horizontal: "left" }}
                        anchorEl={subLinkAnchor}
                        open={Boolean(subLinkAnchor)}
                        onClose={onCloseSubLink}
                        MenuListProps={{ onMouseLeave: onCloseSubLink }}
                        getContentAnchorEl={null}
                    >
                        {/* {
                            sublinks.map((navItem, index) => (
                                <MenuItem key={index} className={classes.menuItem} onClick={() => handleCliclNavs(navItem.id)}>
                                    {navItem.name}
                                </MenuItem>
                            ))
                        } */}
                    </Menu>
                </Toolbar>
            </AppBar>
        </Grow >
    )
}

export default Header;
