import React, { useEffect, useRef } from 'react';
import {
  CssBaseline,
  createMuiTheme,
  makeStyles,
  ThemeProvider,
  useScrollTrigger,
  Zoom,
  Fab
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  Switch,
  Route,
  Redirect,
  useLocation
} from "react-router-dom";

// import { Scrollbars } from 'react-custom-scrollbars';

import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

import * as myConstants from './components/Constants';

import './App.css';
import Header from './components/Header';
import Heading from './components/Heading';
import Products from './components/Products';
import Contact from './components/Contact';
import UnderConstruction from './components/UnderConstruction';
import Mission from './components/Mission';
import WelcomePage from './components/welcome';
import ImgCarousel from './components/ImgCarousel';
import WhatwedoPage from './components/whatwedo';
import Ourpartners from './components/Ourpartners';
import WealthPlanningProcess from './components/WealthPlanningProcess';
import AboutUs from './components/AboutUs';
import Services from './components/Services';
import Howwediffer from './components/Howwediffer';
import Howwehelp from './components/Howwehelp';
import ErrorPage from './components/ErrorPage';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#1de9b6',
      light: '#6effe8',
      dark: '#00b686',
      contrastText: '#1b5e20'
    },
    secondary: {
      main: '#00c853',
      light: '#5efc82',
      dark: '#009624',
      contrastText: '#1b5e20'
    }
  }
});

const useStyles = makeStyles((theme) => ({

  root: {
    width: '100%',
  },
  root2: {
    position: 'fixed',
    zIndex: 1000,
    bottom: theme.spacing(2),
    left: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      bottom: theme.spacing(3),
      left: theme.spacing(3),
    }
  },
  but: {
    background: 'linear-gradient(90deg, rgba(0,191,150,100) 0%, rgba(139,195,74,1) 100%)'
  }

}));

function ScrollTop(props) {
  const { children } = props;
  const classes = useStyles();

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  return (
    <Zoom in={trigger}>
      <div role="presentation" className={classes.root2}>
        {children}
      </div>
    </Zoom>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired
};

const App = (props) => {

  const classes = useStyles();

  const { pathname } = useLocation();

  useEffect(() => {
    topRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, [pathname]);

  const topRef = useRef(null);

  const executeScroll = (ref) => ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' })

  return (
    <div className={classes.root}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div ref={topRef}></div>
        <Header />
        <Switch>
          <Route exact path="/">
            <ImgCarousel />
            <Heading />
            <WelcomePage />
            <Products />
            <Mission />
            <WhatwedoPage />
            <WealthPlanningProcess />
            <Ourpartners />
          </Route>
          <Route exact path={myConstants.NAV_LINKS[1].link}>
            <Services />
            <Howwehelp />
            <UnderConstruction />
          </Route>
          <Route exact path={myConstants.NAV_LINKS[2].link}>
            <AboutUs />
            <Howwediffer />
          </Route>
          <Route exact path='/pagenotfound'>
            <ErrorPage />
          </Route>
          <Redirect to='/pagenotfound' />
        </Switch>
        <Contact />
        <ScrollTop {...props}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <a href={myConstants.WHATSAPP_URL} rel="noreferrer" target="_blank" style={{ margin: '5px' }}>
              <Fab color="secondary" size="small" aria-label="open-whatsapp" style={{ cursor: 'pointer', textDecoration: 'none', color: 'white' }}>
                <WhatsAppIcon />
              </Fab>
            </a>
            <Fab color="secondary" size="small" onClick={() => executeScroll(topRef)} aria-label="scroll back to top" style={{ margin: '5px', color: 'white' }}>
              <KeyboardArrowUpIcon />
            </Fab>
          </div>
        </ScrollTop>
      </ThemeProvider >
    </div>
  )
}

export default App;
