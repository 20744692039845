import React from 'react';
import Helmet from 'react-helmet';

import { Grid, Typography, makeStyles, Grow, Button } from '@material-ui/core';

import * as myConstants from './Constants';

import plan from '../assets/services/plan.svg';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: `${theme.spacing(1)}px`
    },
    hov: {
        display: 'inline-block',
        cursor: 'default',
        backgroundImage: 'linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '20% 0.2em',
        backgroundPosition: '0 95%',
        transition: 'background-size 0.25s ease-in',
        marginBottom: '5vh',
        '&:hover': {
            backgroundSize: '100% 88%'
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: '40px',
            fontSize: '3rem'
        }
    },
    hovSub: {
        display: 'inline-block',
        cursor: 'default',
        backgroundImage: 'linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '20% 0.2em',
        backgroundPosition: '0 95%',
        transition: 'background-size 0.25s ease-in',
        marginBottom: '1vh',
        '&:hover': {
            backgroundSize: '100% 88%'
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: '10px'
        }
    },
    gridContain: {
        height: 'auto',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            height: 'auto',
        }
    },
    gridContain2: {
        height: '70vh',
        width: '100%',
        [theme.breakpoints.down('md')]: {
            height: 'auto',
        }
    },
    content: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },
    content2: {
        maxWidth: '550px',
        fontStyle: 'italic',
        margin: theme.spacing(2),
        textAlign: 'justify'
    },
    consultation: {
        width: '80%',
        fontSize: '1.1rem',
        textAlign: 'center',
        height: '45px',
        transform: 'skew(340deg)',
        backgroundImage: 'linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.9rem',
        }
    },
    cta: {
        marginLeft: theme.spacing(2),
        fontWeight: 'bold',
        color: 'white',
        transform: 'skew(20deg)',
    },
    text: {
        textAlign: 'justify',
        textJustify: 'inter-word'
    },
    svgs: {
        height: '250px',
        margin: '5px',
        [theme.breakpoints.down('sm')]: {
            height: '100px'
        }
    },
}));

const Services = (props) => {

    const classes = useStyles({});

    // React.useEffect(() => {
    //     document.title = 'Retirement Financial Advisor in Dindigul | Pension Find Advisor in Dindigul';
    //     document.description = 'New Horizon is the top notch solution for retirement financial advisor in Dindigul. They also offer pension fund advisor in Dindigul, retirement plan advisor in Dindigul, pension plan in Dindigul & etc.,';
    //     document.keywords = 'Retirement Financial Advisor in Dindigul, Pension Find Advisor in Dindigul, Retirement Plan Advisor in Dindigul, Pension Plan in Dindigul, Pension Plan in Dindigul';
    // }, []);

    return (
        <>
            <Helmet>
                <title>Retirement Financial Advisor in Dindigul | Pension Find Advisor in Dindigul</title>
                <meta name='description' content='New Horizon is the top notch solution for retirement financial advisor in Dindigul. They also offer pension fund advisor in Dindigul, retirement plan advisor in Dindigul, pension plan in Dindigul & etc.,' />
                <meta name='keywords' content='Retirement Financial Advisor in Dindigul, Pension Find Advisor in Dindigul, Retirement Plan Advisor in Dindigul, Pension Plan in Dindigul, Pension Plan in Dindigul' />
                <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                <link rel="canonical" href="https://www.newhorizonwealth.in/services" />
                <meta property="og:locale" content="en_IN" />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Retirement Financial Advisor in Dindigul | Pension Find Advisor in Dindigul" />
                <meta property="og:description" content="New Horizon is the top notch solution for retirement financial advisor in Dindigul. They also offer pension fund advisor in Dindigul, retirement plan advisor in Dindigul, pension plan in Dindigul & etc.," />
                <meta property="og:url" content="https://www.newhorizonwealth.in/services" />
                <meta property="og:site_name" content="New Horizon" />
                <meta property="og:image" content="https://www.newhorizonwealth.in/static/media/logo.c8d9e4cd.png" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:label1" content="Written by" />
                <meta name="twitter:data1" content="New Horizon" />
                <meta name="twitter:label2" content="Est. reading time" />
                <meta name="twitter:data2" content="2 minutes" />
            </Helmet>
            <Grow in>
                <div className={classes.root} id="what-we-do">
                    <Grid container justify="center" alignItems="center" style={{ height: '20vh' }}>
                        <Grid item>
                            <Typography variant="h2" component="div" className={classes.hov}>
                                New Horizons Wealth Services
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justify="center" alignItems="center" spacing={2}>
                        <Grid item>
                            <Typography variant="h5" component="div" className={classes.hovSub}>
                                Plan your future with our best solutions
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container justify="center" spacing={2}>
                        <Grid item xs={10} sm={9} md={9} >
                            <div className={classes.content}>
                                <img src={plan} alt="img" className={classes.svgs} />
                            </div>
                        </Grid>
                        <Grid item xs={10} sm={9} md={9} >
                            <Typography variant="subtitle1" color="textSecondary" className={classes.text}>
                                Often, in today's fast-paced world, you will not have time to plan your wealth wisely or spend some time with your family. We are here for you because we want to make your wealth planning journey as pleasant as possible. With a strong financial team, we implement innovative solutions and speakable strategies to achieve all your future financial goals in a hassle-free manner. Get our wide range of financial services within a reasonable timeframe to eliminate all financial worries.
                            </Typography>
                        </Grid>
                        <Grid item sm={12} className={classes.content}>
                            <Typography className={classes.consultation} >
                                See what we can do to help you succeed
                                <Button variant="contained" color="secondary" className={classes.cta} >
                                    <a href={`tel:${myConstants.SHORT_PHONE_NUMBER}`} style={{ cursor: 'pointer', textDecoration: 'none', color: 'inherit' }}>
                                        Call us : {myConstants.SHORT_PHONE_NUMBER}
                                    </a>
                                </Button>
                            </Typography>
                        </Grid>
                    </Grid>
                </div>
            </Grow >
        </>
    )
}

export default Services