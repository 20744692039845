import React from 'react';

import { Grid, Typography, makeStyles, Grow, Hidden, Button } from '@material-ui/core';

import * as myConstants from './Constants';

import svg14 from '../assets/svg/14.svg';
import svg13 from '../assets/svg/13.svg';
import svg12 from '../assets/svg/12.svg';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: `${theme.spacing(5)}px`
    },
    outlayer: {
        margin: `${theme.spacing(2)}px auto`,
        padding: theme.spacing(2),
        border: '2px solid #84fab0',
        borderRadius: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            margin: `${theme.spacing(0.5)}px`,
            width: 'auto',
        }
    },
    caption: {
        textAlign: 'center',
        color: '#000',
        textTransform: 'uppercase',
        fontWeight: 500,
        marginTop: theme.spacing(2),
        cursor: 'default',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.5rem'
        }
    },
    hov: {
        display: 'inline-block',
        cursor: 'default',
        backgroundImage: 'linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '20% 0.2em',
        backgroundPosition: '0 95%',
        transition: 'background-size 0.25s ease-in',
        marginBottom: '5vh',
        '&:hover': {
            backgroundSize: '100% 88%'
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: '0px',
            fontSize: '3rem'
        }
    },
    gridContain: {
        height: '70vh',
        [theme.breakpoints.down('md')]: {
            height: 'auto',
        }
    },
    svgs: {
        height: '120px',
        transition: '.2s',
        '&:hover': {
            marginLeft: '20px',
        },
        [theme.breakpoints.down('sm')]: {
            height: '100px'
        }
    },
    svgs2: {
        height: '120px',
        transition: '.3s',
        '&:hover': {
            marginRight: '20px',
        },
        [theme.breakpoints.down('sm')]: {
            height: '100px'
        }
    },
    content: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },
    contentText: {
        display: 'flex',
        justifyContent: 'flex-start',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center'
        }
    },
    contentText2: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center'
        }
    },
    text: {
        width: '80%',
        fontSize: '1.2rem',
        textAlign: 'justify',
        textJustify: 'inter-word',
        [theme.breakpoints.down('sm')]: {
            fontSize: '1rem',
        }
    },
    consultation: {
        width: '80%',
        fontSize: '1.1rem',
        textAlign: 'center',
        height: '50px',
        transform: 'skew(340deg)',
        backgroundImage: 'linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.9rem',
            height: '65px',
        }
    },
    cta: {
        marginLeft: theme.spacing(2),
        fontWeight: 'bold',
        color: 'white',
        transform: 'skew(20deg)',
    },
    highlight: {
        width: '150px',
        height: '100px',
        transform: 'skew(20deg)',
        background: 'red'
    }
}));

const WelcomePage = (props) => {

    const classes = useStyles();

    return (
        <Grow in>
            <div className={classes.root} id="welcome-section">
                {/* <Grid container justify="center" alignItems="center" style={{ height: '20vh' }}>
                    <Grid item>
                        <Typography variant="h2" component="div" className={classes.hov}>
                            Our Mission & Vision
                        </Typography>
                    </Grid>
                </Grid> */}
                <Grid container justify="center" alignItems="center" alignContent="center" >
                    <Grid item sm={12} md={4} className={classes.content}>
                        <img src={svg12} alt="img" className={classes.svgs} />
                    </Grid>
                    <Grid item sm={12} md={8} className={classes.contentText}>
                        <Typography color="textSecondary" className={classes.text}>
                            It’s our primes to make your future as bright as the sun and we are committed to it. Here we are
                            to assist you in every finance step with a clear path to make your goals visualized in real and
                            secure your future generation financially.

                        </Typography>
                    </Grid>
                    <Hidden mdUp>
                        <Grid item sm={12} md={4} className={classes.content}>
                            <img src={svg13} alt="img" className={classes.svgs} />
                        </Grid>
                    </Hidden>
                    <Grid item sm={12} md={8} className={classes.contentText2}>
                        <Typography color="textSecondary" className={classes.text}>
                            A warm welcome to New Horizon Wealth Management Services. A Private Wealth Management
                            service provider for all your financial burdensome, right from financial planning, investment,
                            and trading to real estate advisory. Our expert planners and wealth manager are focused on
                            providing a client-centric solution to utter all their specific financial needs by developing a deep
                            relationship with you.

                        </Typography>
                    </Grid>
                    <Hidden smDown>
                        <Grid item sm={12} md={4} className={classes.content}>
                            <img src={svg13} alt="img" className={classes.svgs2} />
                        </Grid>
                    </Hidden>
                    <Grid item sm={12} md={4} className={classes.content}>
                        <img src={svg14} alt="img" className={classes.svgs} />
                    </Grid>
                    <Grid item sm={12} md={8} className={classes.contentText}>
                        <Typography color="textSecondary" className={classes.text}>
                            Our team has a clear understanding of today's wealth market and great insights into the future
                            financial world, we come up with smart and adaptable solutions that suit clients' diverse needs.
                            We carefully craft each financial solution according to the client's lifestyle and future needs.
                        </Typography>
                    </Grid>
                    <Grid item sm={12} className={classes.content}>
                        <Typography className={classes.consultation} >
                            Book a consultation to discuss your financial needs with our expert.
                            <Button variant="contained" color="secondary" className={classes.cta} href={`tel:${myConstants.SHORT_PHONE_NUMBER}`}>
                                Call us : {myConstants.SHORT_PHONE_NUMBER}
                            </Button>
                        </Typography>
                    </Grid>
                </Grid>
            </div>
        </Grow>
    )
}

export default WelcomePage
