import React from 'react';

import { Grid, Typography, makeStyles, Grow } from '@material-ui/core';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faQuoteLeft, faQuoteRight } from '@fortawesome/free-solid-svg-icons';

import svg10 from '../assets/svg/10.png';
import svg11 from '../assets/svg/11.svg';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: `${theme.spacing(6)}px`
    },
    svg: {
        height: '100px',
        width: 'auto',
        [theme.breakpoints.down('sm')]: {
            height: '50px',
            width: 'auto',
        }
    },
    hov: {
        display: 'inline-block',
        cursor: 'default',
        backgroundImage: 'linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '20% 0.2em',
        backgroundPosition: '0 95%',
        transition: 'background-size 0.25s ease-in',
        marginBottom: '5vh',
        '&:hover': {
            backgroundSize: '100% 88%'
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: '0px',
            fontSize: '3rem'
        }
    },
    faicon: {
        fontSize: '10px',
        margin: '0 10px 10px 0',

    },
    content: {
        maxWidth: '550px',
        fontStyle: 'italic',
        margin: theme.spacing(2),
        textAlign: 'justify'
    },
    vertLine: {
        display: 'flex',
        justifyContent: 'center',
        borderLeft: '3px solid #009624',
        borderBottom: 0,
        height: '50%',
        width: 'auto',
        position: 'absolute',
        transition: 'all 0.8s ease-in',
        // left: '49.5%',
        [theme.breakpoints.down('sm')]: {
            borderLeft: 0,
            height: 0,
            width: '50%',
            position: 'absolute',
            borderBottom: '3px solid #009624',
        }
    },
    gridContain: {
        height: '70vh',
        [theme.breakpoints.down('md')]: {
            height: 'auto',
        }
    },
    gridSpacing: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    gridSpacing2: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            marginTop: theme.spacing(2)
        }
    },
    svgs: {
        height: '250px',
        margin: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            height: '100px'
        }
    }
}));

const Mission = (props) => {

    const classes = useStyles();

    return (
        <Grow in>
            <div className={classes.root} id="mission-vision-section">
                <Grid container justify="center" alignItems="center" style={{ height: '20vh' }}>
                    <Grid item>
                        <Typography variant="h2" component="div" className={classes.hov}>
                            Mission & Vision
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container justify="center" alignItems="center" alignContent="center" className={classes.gridContain}>
                    <Grid item xs={10} sm={9} md={5}>
                        <div>
                            <div className={classes.gridSpacing}>
                                <img src={svg10} alt="img" className={classes.svgs} />
                            </div>
                            <div>
                                <Typography variant="body1" className={classes.content}>
                                    <span>
                                        <FontAwesomeIcon icon={faQuoteLeft} className={classes.faicon} />
                                        Our mission is to help people manage their wealth through our actions. We want to reinvent the
                                        way people manage their future investments, including an understanding of the financial future
                                        and the value of wealth. We strive to live up to the client's expectations
                                        <FontAwesomeIcon icon={faQuoteRight} className={classes.faicon} style={{ margin: '0 0px 10px 12px' }} />
                                    </span>
                                </Typography>
                            </div>
                        </div>
                    </Grid>
                    <Grid item style={{ display: 'contents' }}>
                        <div className={classes.vertLine}></div>
                    </Grid>
                    <Grid item xs={10} sm={9} md={5}>
                        <div className={classes.gridSpacing2}>
                            <div>
                                <img src={svg11} alt="img" className={classes.svgs} />
                            </div>
                            <div>
                                <Typography variant="body1" className={classes.content}>
                                    <span>
                                        <FontAwesomeIcon icon={faQuoteLeft} className={classes.faicon} />
                                        We aim to become one of the most trusted financial advisors in India by inspiring people to
                                        believe in a future filled with financial prosperity and creating an innovative solution to secure
                                        and expand the financial boundaries of any individual
                                        <FontAwesomeIcon icon={faQuoteRight} className={classes.faicon} style={{ margin: '0 0px 10px 12px' }} />
                                    </span>
                                </Typography>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </Grow>
    )
}

export default Mission
